/* ==========================================================================
  Book an Appointment
========================================================================== */
.book-appointment {
  @media (min-width: 576px) {
    max-width: 800px;
    margin: 1.75rem auto;
  }

  h5.modal-title {
    @include black-big-head();
    text-align: center;
    font-size: 22px;
    color: #001E3B;
    @include font-weight-style-3(600, normal);
  }

  .btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 1 !important;
  }

  .modal-content {
    background-color: $body-base;
    border-radius: 20px;
  }

  .modal-body {
    padding: 40px 15px;

    .container {
      .col-md-10 {
        margin: 0 auto;
      }

      .form-group {
        margin-bottom: 10px;
      }

      .checkbox-row {
        .checkbox-wrapper {
          width: 500px;

          .checkBtn {
            margin-top: 1rem;
            margin-bottom: 1rem;
          }
        }

        .col-lg-4 {
          padding-left: 0;
        }
      }

      .appointment-btn {
        width: 260px;
        margin: 0 auto;
      }
    }
  }


}