// main background //

$white: #ffffff;
$primary-color: #E74C3C;
$body-base: #FFFAF0;

$base-green: #E9F4F4;
$black: #34495E;
$grey-bg: #FCFCFC;
$black-drak: #131416;


$blue: #23049D;
$light-blue: #009DFF;
$p-color: #39374d;
$form-back: #F7F7FB;

$orange: #FF8E1F;
$blue: #246CFF;
$sea-green: #3DC1CA;
$dark-blue: #00256F;
$purple: #9E2BD1;
$pink: #FF17A8;
$yellow-bg: #FFE9C8;


//font size
$f-10: 10px;
$f-11: 11px;
$f-12: 12px;
$f-13: 13px;
$f-14: 14px;
$f-15: 15px;
$f-16: 16px;
$f-18: 18px;
$f-19: 19px;
$f-20: 20px;


.orange-back {
  background: $orange;
}

.blue-back {
  background: $blue;
}

.sea-green-back {
  background: $sea-green;
}

.dark-blue-back {
  background: $dark-blue;
}

.purple-back {
  background: #9E2BD1;
}