.school-logo-box:empty {
  display: none;
}


/* ==========================================================================
   parents-dashbaord
========================================================================== */
.parents-dashbaord {
  padding: 80px 0 0 0;

  .banner-back {
    background: url(../img/new-home/header-bg.png) no-repeat top left #001E3B;

    .container {
      @media (max-width: 767px) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
      }
    }
  }

  .left-item {

    @media (min-width: 767px) {
      max-width: 400px;
    }
  }

  .right-item {

    img {
      max-width: 360px;
    }

    @media (min-width: 767px) {
      display: block;
    }
  }

  .parents-dashbaord-content {
    h3 {
      @include black-big-head();
      font-size: 24px;
      @include font-weight-style(700, normal);
    }
  }

  .dash-blue-card {
    display: flex;

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
  }
}


.dashboard-instructions {
  width: 100%;

  .qr-banner {
    padding: 40px 40px;
    width: 100%;
    position: relative;
    overflow: hidden;
    background: url(../img/new-home/contact-bg.png) no-repeat left center $white;
    border-radius: 20px;
    background-size: cover;

    @media (max-width: 767px) {
      padding: 40px 20px;
    }

    .row {
      width: 100%;
      align-items: center;
    }

    .left-data {

      h3 {
        @include black-big-head();
        font-size: 20px;
        @include font-weight-style(700, normal);

        @media (max-width: 767px) {
          font-size: 20px;
        }
      }

      p {
        @include p();
        margin: 0;
        color: $black;
        font-size: 14px;
        @include font-weight-style-3(400, normal);
      }

      ul {
        margin: 0;

        li {
          font-size: 14px;
        }
      }

      .qr-scan {
        display: flex;
        align-items: center;
        margin: 40px 0 0 0;

        .scan-img {
          max-width: 160px;

          @media (max-width: 640px) {
            max-width: 120px;
          }
        }

        .download {
          padding-left: 30px;

          p {
            font-size: 16px;
            @include font-weight-style-3(600, normal);

            span {
              display: block;
              @include font-weight-style-3(400, normal);
            }
          }

          a {
            padding: 0;
            text-align: left;
            width: 180px;

            @media (max-width: 640px) {
              width: 140px;
            }

            img {
              max-width: 100%;
            }
          }
        }
      }
    }
  }

  .dash-ins-info {
    margin: 40px 0;

    .mobile-heading {
      pointer-events: none;
    }

    .banner-cloud {
      display: none;
    }

    .order-list-data {
      margin-top: 0;
    }

    h3 {
      @include black-big-head();
      @include font-weight-style-3(600, normal);
      margin: 0;
      font-size: 18px;
      line-height: 34px;

      @media (max-width: 992px) {
        font-size: 20px;
      }
    }

    p {
      @include p();
      margin-bottom: 0;
      @include font-weight-style-3(400, normal);
      font-size: 14px;

      a {
        color: $blue;
        text-decoration: underline;
        @include font-weight-style-3(600, normal);
      }
    }

    ol.order-list-data {
      li {
        font-size: 14px !important;
      }
    }

    ul.bullet-list-data {
      li {
        font-size: 14px !important;
      }
    }
  }

}

/* ==========================================================================
   school-dashbaord
========================================================================== */

.school-dashbaord-cards {
  display: flex;
  flex-wrap: wrap;

  .col-md-4 {
    display: flex;
    min-height: 150px;
    margin-bottom: 10px;
  }

  .card {
    padding: 40px 20px;
    width: 100%;
  }
}

.school-dash-blue-card {
  display: flex;

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }

  .left-blue-card {

    @media (max-width: 1024px) {
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    p {
      margin-bottom: 5px;
    }

    .link-tag {
      width: 100%;

      a {
        word-break: break-all;
      }

      @media (max-width: 767px) {
        flex-wrap: wrap;
      }
    }

    // .img-box {
    //   @media (max-width: 1024px) {
    //     .svg-box {
    //       svg {
    //         width: 90px !important;
    //       }
    //     }
    //   }
    // }
  }
}

.school-logo-row {
  @media (max-width: 767px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .practido-log {
    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
}

/* ==========================================================================
  student-view-container
========================================================================== */
.student-view-container {
  padding: 40px 15px;

  .student-detail-row {
    background-color: $white;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 2px 0px 0px #DFDFDF;

    .table-container {
      margin: 0;

      .table-header-row {
        background: transparent;
        padding: 0;
        justify-content: space-between;

        div {
          color: $black;
          font-size: 22px;
          @include font-weight-style-3(400, normal);

          @media (max-width: 992px) {
            font-size: 18px;
          }

          @media (max-width: 767px) {
            height: 50px;
            font-size: 18px;
          }
        }
      }

      .table-data-row {
        background: transparent;
        padding: 0;
        justify-content: space-between;

        div {
          color: $black;
          font-size: 20px;
          @include font-weight-style-3(600, normal);

          @media (max-width: 767px) {
            height: 50px;
            font-size: 16px;
          }
        }
      }
    }
  }

  .student-detail-card {
    background-color: $white;
    border-radius: 20px;
    margin-bottom: 40px !important;
    padding: 20px;
    overflow: auto;
    box-shadow: 0px 2px 0px 0px #DFDFDF;
  }

  .add-subject-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    h3 {
      @include black-big-head();
      @include font-weight-style-3(600, normal);
      margin: 0;
      font-size: 20px;
      line-height: 34px;

      @media (max-width: 992px) {
        font-size: 16px;
      }
    }

    .btn {
      font-size: 16px !important;
      min-width: 250px !important;
    }
  }
}

/* ==========================================================================
  table-wrapper
========================================================================== */

.table-wrapper {
  box-shadow: none !important;
  overflow: auto;
  margin-top: 10px !important;
  box-shadow: 0px 2px 0px 0px #DFDFDF !important;

  th {
    p {
      font-size: 16px;
      color: $black-drak !important;
      opacity: 1 !important;
      @include font-weight-style-3(600, normal);
    }
  }

  td {
    p {
      font-size: 14px;
      @include font-weight-style-3(400, normal);
    }
  }
}

.table-card {
  overflow-y: auto;

  @media (min-width: 767px) {
    margin-bottom: 20px;
  }
}

/* ==========================================================================
  step3-edit
========================================================================== */
.step3-edit-container {
  @media (max-width: 767px) {
    padding: 0;
  }

  .step3-edit-content {
    .edit-form-box {
      padding: 40px;

      @media (max-width: 767px) {
        padding: 20px;
      }
    }
  }
}

/* ==========================================================================
  step3-edit
========================================================================== */
.registered-students-container {

  @media (min-width: 1200px) {
    max-width: 1260px !important;
  }

  @media (min-width: 1300px) {
    max-width: 1300px !important;
  }
}