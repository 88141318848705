.radioBtn {
    @apply cursor-pointer flex items-center rounded-[5px] relative
}

.radioLabel{
    @apply flex items-center w-full pl-8 text-xs;
}

.radioBtn input:checked~.radioLabel {
    @apply transition duration-300;
}

.radioLabel::before {
    @apply w-[13px] h-[13px] absolute left-[4px] rounded-full outline outline-[3px] outline-[#C4C4C4] outline-offset-[3px];
    content: "";
}

.radioLabel::after {
    @apply absolute left-[5px] w-3 h-3 rounded-full transition duration-500;
    content: "";
}

.radioBtn input:checked~.radioLabel::after {
    @apply self-center bg-[#009DFF]
}




.radioBtn-assessment{
    @apply cursor-pointer
}

.radioLabel-assessment{
    @apply  flex items-center bg-[#ECF0F1] hover:bg-[#e5e5e5] min-h-[80px] pl-8 rounded-[16px] text-lg font-bold text-[#34495E];
    box-shadow: 0px 4px 0px 0px #BDC3C7;
}

.radioBtn-assessment input:checked~.radioLabel-assessment{
    @apply bg-[#F1C40F] transition duration-300; 
    box-shadow: 0px 4px 0px 0px #F2B010;
}

