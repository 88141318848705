.textInput, .selectInput {
	@apply h-[40px] w-full bg-[#F5F5F5] rounded-lg px-3 text-sm border-[1px] border-[#c2c2c2];
}

.textArea{
	@apply w-full bg-[#F5F5F5] rounded-lg px-3 border-0 text-xs;
}

.fileInputContainer{
	@apply h-[40px] w-full bg-[#F5F5F5] rounded-lg px-3 border-0 text-xs p-3 relative;
}
.fileInput{
	@apply absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer;
}

/* .textInput::placeholder,
.textArea::placeholder,
.fileInputPlaceholder {
	@apply font-normal text-xs text-[#C4C4C4];
} */

.textInput:active,
.textInput:focus,
.selectInputect:active,
.selectInput:focus,
.textArea:active,
.textArea:focus  {
	@apply outline-none ring-0;
}

input[type="datetime-local"]::-webkit-datetime-edit-text { color: #aaa; padding: 0 0.3rem; }
input[type="datetime-local"]::-webkit-datetime-edit-month-field { color: #aaa;}
input[type="datetime-local"]::-webkit-datetime-edit-day-field { color: #aaa; }
input[type="datetime-local"]::-webkit-datetime-edit-year-field { color: #aaa; }
input[type="datetime-local"]::-webkit-datetime-edit-hour-field { color: #aaa; }
input[type="datetime-local"]::-webkit-datetime-edit-minute-field { color: #aaa; }

.error{
	@apply border-2 border-[#ff0000]
}

.eye{
	/* position: absolute;
	right: 20px;
	top: 30px;
	cursor: pointer;
	width: 20px;
	height: 20px; */
  }