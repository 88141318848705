/* ==========================================================================
   parent-banner
========================================================================== */
.parent-banner {
  padding: 145px 20px 20px 20px;
  background-color: #FFF3E7;
  height: 750px;
  position: relative;

  @media (max-width: 992px) {
    height: auto;
    padding: 100px 20px 50px 20px;
  }


  .container {
    min-height: 100%;
    display: flex;
  }

  @media (max-width: 992px) {
    .banner-icon {
      left: 0;

      span {
        margin: 0 auto;
        display: block;
        text-align: center;
      }
    }
  }

  .row {
    min-height: 100%;
    display: flex;
    align-items: center;

    .order-change {
      @media (max-width: 992px) {
        order: 1;
      }
    }

    .col-lg-6 {
      &.right-item {
        .img-box {
          max-width: 500px;
          margin: 0 auto;
          position: relative;
        }

        .item-1 {
          position: absolute;
          left: 6%;
          top: 10%;
          width: 70px;

          @media (max-width: 1024px) {
            width: 60px;
            left: 8%;
          }

          @media (max-width: 992px) {
            left: 13%;
          }

          @media (max-width: 767px) {
            width: 42px;
            left: 5%;
          }
        }

        .item-2 {
          position: absolute;
          top: 0;
          left: 35%;
          width: 70px;

          @media (max-width: 1024px) {
            width: 60px;
          }


          @media (max-width: 767px) {
            width: 42px;
          }
        }

        .item-3 {
          position: absolute;
          left: 35%;
          top: 20%;
          width: 70px;

          @media (max-width: 1024px) {
            width: 60px;
          }

          @media (max-width: 992px) {
            top: 18%;
          }

          @media (max-width: 767px) {
            width: 42px;
            left: 32%;
          }
        }
      }

      img {
        margin: 0 auto;
        max-width: 480px;

        @media (max-width: 1400px) {
          max-width: 450px;
          height: auto;
        }

        @media (max-width: 1024px) {
          max-width: 400px;
          height: auto;
        }

        @media (max-width: 767px) {
          max-width: 100%;
          height: auto;
        }

        @media (max-width: 767px) {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }

  h3 {
    @include black-big-head();
    @include font-weight-style-3(600, normal);

    @media (max-width: 1024px) {
      font-size: 32px;
    }

    @media (max-width: 992px) {
      text-align: center;
      margin-top: 30px;
    }

    @media (max-width: 767px) {
      font-size: 22px;
    }

  }

  p {
    @include p();
    font-size: 18px;
    @include font-weight-style-3(400, normal);

    @media (max-width: 992px) {
      font-size: 15px;
      text-align: center;
    }

    &.bold {
      font-size: 22px;
      margin-top: 30px;

      @media (max-width: 992px) {
        font-size: 20px;
      }
    }
  }

  .btn-inline {
    margin-top: 30px;

    @media (max-width: 992px) {
      justify-content: center;
      flex-direction: column;

      .btn+.btn {
        margin: 10px 0 0 0;
      }
    }

    .btn {
      border-radius: 20px !important;
      height: 56px;
      font-size: 18px;
      min-width: 180px;
      padding: 10px;

      &.blue-btn {
        min-width: 260px;
      }

      @media (max-width: 992px) {
        min-width: 300px !important;
        @include font-weight-style-3(700, normal);
      }

      @media (max-width: 640px) {
        min-width: 100% !important;
        font-size: 16px;
      }
    }


  }
}


/* ==========================================================================
   our feature
	========================================================================== */
.parent-our-feature {
  padding: 60px 0 60px 0;
  overflow: hidden;
  background: #FFFCF6;

  @media (max-width: 1024px) {
    padding: 60px 15px 50px 15px;
  }

  .container {
    .top-head {
      max-width: 990px;
      margin: 0 auto 50px;
    }

    h3 {
      @include black-big-head();
      text-align: center;
      font-size: 34px;
      line-height: 42px;
      max-width: 800px;
      margin: 0 auto 15px;
      @include font-weight-style-3(700, normal);

      @media (max-width: 992px) {
        font-size: 22px;
        line-height: 30px;
      }

      span {
        display: block;
        font-size: 24px;
        color: #2B2E9F;
        margin-top: 10px;

        @media (max-width: 992px) {
          font-size: 18px;
        }
      }
    }

    p {
      @include p();
      text-align: center;
      font-size: 18px;
      @include font-weight-style-3(500, normal);
    }

    ul {
      @include p();
      padding: 0 20px;
      margin: 20px 0 0 0;

      li {
        list-style-type: disc;
        padding: 0;
        font-size: 16px;
        @include font-weight-style(400, normal);

        .bold-txt {
          color: $black;
          @include font-weight-style(500, normal);
        }
      }
    }

    .row {
      align-items: center;

      &+.row {
        margin-top: 60px;

        @media (max-width: 767px) {
          margin-top: 30px;
        }
      }

      &.order-change {
        @media (max-width: 767px) {
          flex-direction: column-reverse;
        }
      }

      .content {
        padding: 0 15px;

        @media (max-width: 1024px) {
          padding: 0;
        }
      }

      h3 {
        text-align: left;
        @include black-big-head();
        line-height: 40px;
        font-size: 26px;
        @include font-weight-style-3(700, normal);

        @media (max-width: 992px) {
          font-size: 20px;
          line-height: 26px;
        }
      }

      p {
        @include p();
        text-align: left;
        font-size: 16px;
        @include font-weight-style-3(400, normal);

        @media (max-width: 992px) {
          font-size: 14px;
        }

      }

      .img-box {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;

        @media (max-width: 767px) {
          margin-bottom: 30px;
        }

        img {
          width: 100%;
          max-width: 450px;
          height: 100%;

          @media (max-width: 767px) {
            text-align: center;
            margin: 0 auto;
          }
        }
      }
    }
  }
}

/* ==========================================================================
   parent-challenge
	========================================================================== */
.parent-challenge {
  background: #1C214D;
  display: flex;
  align-items: center;
  z-index: 1;

  @media (max-width: 1024px) {
    padding: 50px 15px 50px 15px;
  }

  @media (max-width: 992px) {
    min-height: auto;
    padding: 20px 15px 20px 15px;
  }

  &.video-challenge {
    @media (max-width: 992px) {
      padding: 0;
    }
  }

  &.parent-landing {
    min-height: 550px;

    .container {
      top: 0;
      left: 0;

      @media (max-width: 1024px) {
        max-width: 100%;
      }
    }
  }

  .container,
  .container-fluid {
    display: flex;
    align-items: center;
    min-height: 100%;
    position: relative;

    @media (max-width: 1366px) {
      max-width: 1000px;
      top: 50px;
    }

    @media (max-width: 1024px) {
      max-width: 850px;
    }

    @media (max-width: 992px) {
      min-height: auto;
      flex-direction: column;
      top: 0;
      left: 0;
    }

    .order-change {
      @media (max-width: 992px) {
        flex-direction: column-reverse;
      }
    }

    h3 {
      @include black-big-head();
      color: $white;
      font-size: 26px;
      text-align: center;
      @include font-weight-style-3(700, normal);

      @media (max-width: 1366px) {
        font-size: 22px;
      }

      @media (max-width: 1024px) {
        font-size: 18px;
      }

      span {
        display: block;
        font-size: 22px;
        margin-top: 10px;
        @include font-weight-style-3(300, normal);

        @media (max-width: 1366px) {
          font-size: 20px;
        }

        @media (max-width: 1024px) {
          font-size: 16px;
        }
      }
    }

    p {
      @include p();
      color: $white;
      text-align: center;
      font-size: 16px;
      @include font-weight-style-3(400, normal);

      &.left {
        text-align: left;
      }
    }

    &.video-container {
      position: relative;
      width: 100%;
      max-width: 100%;
      padding: 0;
      height: 100%;
      overflow: hidden;

      @media (max-width: 1366px) {
        top: 0;
      }

      @media (max-width: 992px) {
        min-height: auto;
      }

      .video-container-inner {
        width: 100%;
        position: relative;
        left: 0;
        // top: 80px;
        z-index: 1000;
        // height: calc(100vh - 80px);
        height: 100%;

        @media (max-width: 1024px) {
          top: 0;
          height: 100%;
          min-height: 700px;
        }

        @media (max-width: 992px) {
          position: relative;
          min-height: 100%;
          top: 0;
        }
      }

      video {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      img {
        @include img();

        @media (max-width: 992px) {
          min-height: 200px;
        }
      }
    }


  }
}

.parent-challenge {
  &.gsap-panel {
    background: transparent;
  }

  &.gsap-panel-8 .feature-box {
    padding: 0 !important;
  }
}

/* ==========================================================================
   parent-pdf-section
	========================================================================== */
.parent-pdf-section {
  padding: 80px 0;
  background: #FFFCF6;
  overflow: hidden;

  @media (max-width: 992px) {
    background: $white;
    padding: 20px 0;
  }

  h3 {
    text-align: center;
    @include black-big-head();
    font-size: 35px;
    @include font-weight-style-3(600, normal);

    @media (max-width: 992px) {
      font-size: 22px;
    }
  }

  p {
    font-size: 16px;
    @include font-weight-style-3(400, normal);
  }

  .feature-wrapper {
    .container {
      .feature-box {
        align-items: center;

        @media (max-width: 992px) {
          padding: 0 15px 20px 15px;
          margin-top: 0;
        }

        .img-box {
          position: relative;
          max-width: 635px;

          @media (max-width: 1366px) {
            max-width: 580px;
          }

          @media (max-width: 992px) {
            top: 0;
            margin: 0 auto;
            height: auto;
            max-width: 100%;
          }

          @media (max-width: 640px) {
            height: auto;
          }

          .cloud {
            position: absolute;
            top: 35%;
            left: 65px;

            @media (max-width: 1024px) {
              width: 60px;
            }

            @media (max-width: 992px) {
              left: 80px;
            }

            @media (max-width: 767px) {
              left: 40px;
              width: 35px;
            }
          }

          .folder {
            position: absolute;
            top: 35%;
            right: 110px;

            @media (max-width: 1024px) {
              width: 45px;
              right: 93px;
            }

            @media (max-width: 992px) {
              right: 115px;
            }

            @media (max-width: 767px) {
              right: 60px;
              width: 35px;
            }
          }
        }

        .feature-img {
          max-width: 100%;
        }
      }

      .para-box {
        h3 {
          text-align: left;
          font-size: 30px;
          @include font-weight-style-3(700, normal);

          @media (max-width: 992px) {
            font-size: 20px;
          }
        }

        h4 {
          font-size: 24px;
          text-align: left;
          margin-top: 100px;
          @include font-weight-style-3(600, normal);

          @media (max-width: 992px) {
            margin-top: 50px;
            font-size: 20px;
          }
        }

        p {
          margin-top: 20px;

          @media (max-width: 992px) {
            font-size: 14px;
          }

          &.bold {
            font-size: 20px;
          }
        }
      }


    }
  }
}

.banner-icon {
  top: 10px;
  display: block;
  position: relative;
  left: -20px;

  span {
    width: 200px;
    height: auto;
    display: block;

    @media (max-width: 767px) {
      width: 150px;
    }

    a {
      cursor: pointer;
      outline: 0 !important;
      box-shadow: none !important;
      width: 100%;
      min-width: 200px;
      padding: 0;
      justify-content: flex-start;

      @media (max-width: 767px) {
        min-width: 150px;
      }
    }

    img {
      @include img();
      width: 200px;
    }
  }
}

/* ==========================================================================
   parent-practido
	========================================================================== */
.parent-practido {
  background: #1C214D;
  padding: 50px 0;
  overflow: hidden;

  @media (min-width: 1400px) {
    padding: 80px 0;
  }

  .container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .para-box {
      padding: 0 20px 0 40px;

      @media (max-width: 992px) {
        padding: 0 15px;
      }
    }
  }

  .head {
    max-width: 700px;
    margin: 0 auto 60px;
    display: block;

    @media (max-width: 767px) {
      margin: 0 auto 50px;
    }
  }

  h3 {
    text-align: center;
    @include black-big-head();
    @include font-weight-style-3(600, normal);
    color: $white;

    @media (max-width: 992px) {
      font-size: 22px;
    }

    span {
      display: block;
      font-size: 16px;
      margin-top: 10px;
      padding: 0 80px;
      @include font-weight-style-3(400, normal);

      @media (max-width: 768px) {
        font-size: 15px;
        padding: 0;
      }
    }
  }

  h4 {
    text-align: center;
    @include black-big-head();
    font-size: 24px;
    @include font-weight-style-3(700, normal);
    margin: 20px 0;
    text-align: center;
    color: $white;

    @media (max-width: 992px) {
      font-size: 16px;
    }

    @media (max-width: 767px) {
      text-align: center;
      margin: 10px 0;
    }
  }

  p {
    @include p();
    color: $white;
    @include font-weight-style-3(300, normal);

    &.top-margin {
      margin-top: 20px;
    }
  }

  .col-md-4 {
    text-align: center;
    margin-bottom: 30px;

    .img-box {
      width: 100%;
      position: relative;

      .first-img {
        position: absolute;
        top: 18px;
        left: 101px;
        width: 52px;

        @media (max-width: 1024px) {
          left: 73px;
        }

        @media (max-width: 992px) {
          left: 53px;
          width: 45px;
        }
      }

      .sec-img {
        position: absolute;
        top: 84px;
        right: 82px;
        width: 28px;

        @media (max-width: 1024px) {
          right: 54px;
        }

        @media (max-width: 992px) {
          right: 5px;
        }

        @media (max-width: 767px) {
          right: 25px;
        }
      }

      .bulb-img {
        position: absolute;
        top: 10px;
        left: 153px;
        width: 38px;

        @media (max-width: 1024px) {
          top: 8px;
          left: 123px;
          width: 40px;
        }

        @media (max-width: 992px) {
          left: 93px;
        }
      }

      .first-qus {
        position: absolute;
        top: 76px;
        left: 75px;
        width: 22px;

        @media (max-width: 1024px) {
          left: 49px;
        }

        @media (max-width: 992px) {
          left: 26px;
        }
      }

      .sec-qus {
        position: absolute;
        top: 129px;
        left: 120px;
        width: 22px;

        @media (max-width: 1024px) {
          left: 100px;
        }

        @media (max-width: 992px) {
          left: 67px;
        }
      }

      .left-first-qus {
        position: absolute;
        top: 34px;
        right: 98px;
        width: 20px;

        @media (max-width: 1024px) {
          right: 70px;
        }

        @media (max-width: 992px) {
          right: 20px;
          width: 18px;
        }

        @media (max-width: 767px) {
          right: 36px;
        }
      }

      .left-sec-qus {
        position: absolute;
        top: 111px;
        right: 68px;
        width: 20px;

        @media (max-width: 1024px) {
          right: 40px;
        }

        @media (max-width: 992px) {
          right: -7px;
          width: 20px;
        }

        @media (max-width: 767px) {
          right: 10px;
        }
      }
    }

    .img-box {
      @media (max-width: 767px) {
        margin: 0 auto;
        display: block;
        max-width: 220px;
      }
    }

    img {
      margin: 0 auto;
      display: block;
      max-width: 220px;

      @media (max-width: 767px) {
        max-width: 100%;
      }
    }
  }

  ol {
    margin-top: 20px;
    list-style-type: decimal;
    padding-left: 20px;

    li {
      @include p();
      color: $white;
      @include font-weight-style-3(400, normal);

      span {
        @include font-weight-style-3(600, normal);
      }

      &+li {
        margin-top: 20px;
      }
    }
  }


  .btn {
    border-radius: 20px !important;
    height: 56px;
    font-size: 18px;
    min-width: 260px;
    padding: 10px;

    &.blue-btn {
      min-width: 260px;
    }

    &.book-btn {
      margin: 20px 0;
    }

    @media (max-width: 992px) {
      min-width: 300px !important;
      @include font-weight-style-3(700, normal);
    }

    &.link-btn {
      color: #FEAA00;
      text-align: center;
      margin: 0 auto;
      outline: 0 !important;
      box-shadow: none !important;
      @include font-weight-style-3(700, normal);

      @media (max-width: 992px) {
        min-width: 100% !important;
      }
    }

  }

  &.join-us {
    padding: 100px 0;

    @media (max-width: 992px) {
      padding: 50px 0;
    }

    h3 {
      text-align: left;

      @media (max-width: 767px) {
        margin-top: 40px;
      }
    }
  }
}


/* ==========================================================================
   about-banner
========================================================================== */
.about-banner {
  padding: 0 20px 20px 20px;
  height: 750px;
  position: relative;

  @media (max-width: 992px) {
    height: auto;
    // padding: 100px 20px 20px 20px;
    padding: 50px 20px 20px 20px;
  }

  .container {
    min-height: 100%;
    display: flex;
  }

  .row {
    min-height: 100%;
    display: flex;
    align-items: center;

    .order-change {
      @media (max-width: 992px) {
        order: 1;
      }
    }

    .col-lg-6 {
      &.left-item {
        padding-top: 150px;

        @media (max-width: 992px) {
          padding-top: 0;
        }
      }

      img.about-banner-img {
        margin: 0 auto;
        max-width: 100%;
        position: absolute;
        // bottom: -322px;
        bottom: -268px;
        right: -29px;
        width: 640px;
        height: 552px;

        @media (max-width: 1024px) {
          right: 0;
          // bottom: -270px;
          bottom: -215px;
          height: 500px;
        }

        @media (max-width: 992px) {
          max-width: 100%;
          height: auto;
          bottom: auto;
          top: -19px;
          position: relative;
        }
      }
    }
  }

  h3 {
    @include black-big-head();
    @include font-weight-style-3(600, normal);
    color: $white;

    @media (max-width: 1024px) {
      font-size: 32px;
    }

    @media (max-width: 992px) {
      text-align: center;
      margin-top: 30px;
    }

    @media (max-width: 767px) {
      font-size: 22px;
    }
  }

  h4 {
    font-size: 26px;
    color: $white;
    text-align: left;
    margin: 20px 0 10px 0;
    @include font-weight-style-3(600, normal);

    @media (max-width: 1366px) {
      font-size: 24px;
    }

    @media (max-width: 992px) {
      font-size: 20px;
      text-align: center;
    }
  }

  p {
    @include p();
    font-size: 18px;
    color: #FFFAF0;
    @include font-weight-style-3(400, normal);

    @media (max-width: 992px) {
      font-size: 15px;
      text-align: center;
    }

  }

  .btn-inline {
    position: relative;
    left: -40px;

    @media (max-width: 992px) {
      left: 0;
    }

    a {
      width: 220px;
      text-align: left;
      outline: 0 !important;
      box-shadow: none;

      img {
        width: 100%;
        height: auto;
      }
    }

    @media (max-width: 992px) {
      justify-content: center;
      flex-direction: column;

      .btn+.btn {
        margin: 10px 0 0 0;
      }
    }

    // .btn {
    //   border-radius: 20px !important;
    //   height: 56px;
    //   font-size: 18px;
    //   min-width: 180px;
    //   padding: 10px;

    //   &.blue-btn {
    //     min-width: 280px;
    //   }

    //   @media (max-width: 992px) {
    //     min-width: 300px;
    //     @include font-weight-style-3(700, normal);
    //   }
    // }
  }
}