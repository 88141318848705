/* ==========================================================================
   educator-banner
========================================================================== */
.educator-back {
  background: url(../img/new-home/header-bg.png) no-repeat top center #1C214D;
  height: 700px;
  padding: 100px 0 0 0;
  background-size: cover;

  @media (min-width: 1400px) {
    min-height: 750px;
  }

  @media (max-width: 992px) {
    min-height: 530px;
    height: 100%;
    padding: 150px 0 50px 0;
  }

  @media (max-width: 767px) {
    min-height: 600px;
  }

  h3 {
    color: $white;
  }

  p {
    color: $white;
    margin-bottom: 10px;
    @include font-weight-style-3(300, normal);

    &.bold {
      @include font-weight-style-3(600, normal);
    }
  }

  .btn-inline .btn {
    min-width: 260px;
  }

  .educator-img {
    max-width: 500px;
    margin: 0 auto;
    display: block;
    position: relative;

    @media (max-width: 992px) {
      margin: 0 auto 40px;
    }

    .educator-banner {
      width: 480px;
    }

    .boy {
      position: absolute;
      right: 0;
      left: 0;
      bottom: -30px;
      width: 300px !important;
    }
  }
}

/* ==========================================================================
  educator-challenge
========================================================================== */
.educator-challenge {
  padding: 80px 0 80px 0;
  overflow: hidden;

  @media (max-width: 767px) {
    padding: 50px 0;
  }

  .container {
    @media (max-width: 1280px) {
      max-width: 1000px;
    }

    @media (max-width: 1024px) {
      max-width: 800px;
    }
  }

  .bottom-container {
    padding: 0;

    .bottom-data {
      width: 100%;
      padding-right: 100px;

      @media (max-width: 1600px) {
        padding-right: 50px;
      }

      .row {
        margin-left: auto;
        max-width: 70%;

        @media (max-width: 1366px) {
          max-width: 80%;
        }

        @media (max-width: 992px) {
          max-width: 100%;
        }


      }
    }

  }

  .col-md-6 {
    margin-bottom: 40px;

    &:first-child {
      margin-top: 100px;

      @media (max-width: 767px) {
        margin-top: 50px;
      }
    }

    &:nth-child(2) {
      @media (min-width: 768px) {
        position: relative;
        top: -20px;
      }
    }

    &:nth-child(4) {
      @media (min-width: 768px) {
        position: relative;
        top: -80px;
      }
    }
  }

  h3 {
    @include black-big-head();
    font-size: 36px;
    text-align: left;
    max-width: 480px;
    @include font-weight-style-3(700, normal);

    @media (max-width: 1024px) {
      font-size: 30px;
    }

    @media (max-width: 767px) {
      font-size: 22px;
      text-align: center;
    }
  }

  h4 {
    @include black-big-head();
    font-size: 20px;
    text-align: left;
    margin: 20px 0 10px 0;
    @include font-weight-style-3(700, normal);

    @media (max-width: 767px) {
      text-align: center;
      font-size: 20px;
    }
  }

  p {
    @include p();
    margin-bottom: 0;
    @include font-weight-style-3(400, normal);

    @media (max-width: 767px) {
      text-align: center;
    }
  }

  .img-box {
    max-width: 150px;

    img {
      @include img();
    }

    @media (max-width: 767px) {
      margin: 0 auto;
    }
  }

  .btn-inline {
    @media (max-width: 767px) {
      flex-direction: column;
    }

    .btn {
      border-radius: 20px;
      height: 56px;
      font-size: 18px;
      min-width: 260px;

      @media (max-width: 767px) {
        margin: 0;

        &:first-child {
          margin-bottom: 10px;
        }
      }
    }
  }
}

/* ==========================================================================
  educator-upcoming-feature
========================================================================== */
.educator-upcoming-feature {
  background: #246CFF;
  padding: 50px 0 0 0;
  width: 100%;
  overflow: hidden;

  @media (max-width: 767px) {
    padding: 50px 0;
  }

  .container {
    @media (max-width: 1280px) {
      max-width: 1000px;
    }

    @media (max-width: 1024px) {
      max-width: 800px;
    }
  }

  .head {
    max-width: 600px;
    margin: 0 auto;
  }

  h3 {
    @include black-big-head();
    color: $white;
    text-align: center;
    @include font-weight-style-3(700, normal);

    @media (max-width: 1024px) {
      font-size: 30px;
    }

    @media (max-width: 767px) {
      font-size: 22px;
    }
  }

  p {
    @include p();
    color: $white;
    text-align: center;
    @include font-weight-style-3(400, normal);
  }

  .upcoming-slider {
    margin-top: 40px;

    .slick-prev:before,
    .slick-next:before {
      font-size: 0 !important;
    }

    .slick-next:before {
      content: '';
      display: block;
      background: url(../../../public/img/educator-home/slider-arrow-right.png) no-repeat;
      background-size: 100%;
      width: 60px;
      height: 60px;
    }

    .slick-prev:before {
      content: '';
      display: block;
      background: url(../../../public/img/educator-home/slider-arrow-left.png) no-repeat;
      background-size: 100%;
      width: 60px;
      height: 60px;
    }

    .slick-prev {
      left: -70px;
    }

    .slick-dots li button:before {
      font-size: 10px;
      color: rgba($white, 0.3);
    }

    .slick-dots li.slick-active button:before {
      opacity: .75;
      color: $white;
    }

    .slider-item {
      background: $white;
      text-align: center;
      margin: 0 auto;
      padding: 40px;
      height: 480px;
      border-top-left-radius: 80px;
      border-top-right-radius: 80px;

      @media (min-width: 1400px) {
        height: 500px;
      }

      @media (max-width: 767px) {
        height: auto;
      }

      .content {
        margin: 0 auto;
        max-width: 750px;
      }

      img {
        margin: 0 auto 30px;

        @media (max-width: 1400px) {
          width: 300px;
          height: auto;
        }

        @media (max-width: 1400px) {
          width: 250px;
          height: auto;
        }
      }

      h3 {
        @include black-big-head();
        @include font-weight-style-3(700, normal);
        font-size: 30px;
        text-align: center;
        line-height: 36px;

        @media (max-width: 1024px) {
          font-size: 30px;
        }

        @media (max-width: 767px) {
          font-size: 22px;
          line-height: 30px;
        }
      }

      p {
        @include p();
        text-align: center;
        @include font-weight-style-3(400, normal);
      }
    }
  }
}


/* ==========================================================================
  how it work
========================================================================== */
.how-it-work {
  background: #246CFF;
  padding: 50px 0;
  width: 100%;
  overflow: hidden;

  @media (min-width: 1200px) {
    padding: 100px 0;
  }

  .container {
    @media (min-width: 1200px) {
      max-width: 1300px;
    }

  }



  h3 {
    @include black-big-head();
    font-size: 36px;
    text-align: center;
    color: $white;
    margin-bottom: 10px;
    @include font-weight-style-3(700, normal);

    @media (max-width: 1024px) {
      font-size: 30px;
    }

    @media (max-width: 767px) {
      font-size: 22px;
      text-align: center;
    }
  }

  h4 {
    @include black-big-head();
    font-size: 20px;
    text-align: left;
    margin: 20px 0 10px 0;
    @include font-weight-style-3(700, normal);

    @media (max-width: 767px) {
      text-align: center;
      font-size: 20px;
    }
  }

  p {
    @include p();
    color: $white;
    text-align: center;
    margin-bottom: 0;
    @include font-weight-style-3(400, normal);

    @media (max-width: 767px) {
      text-align: center;
    }

    &.big {
      font-size: 20px;
      max-width: 600px;
      margin: 0 auto;

      @media (max-width: 767px) {
        font-size: 16px;
      }
    }
  }

  .sign-up-btn {
    color: #001E3B;
    background-color: $white !important;
    box-shadow: $white !important;
    min-width: 200px;
    margin: 20px auto 60px;
    @include font-weight-style-3(600, normal);
  }

  .img-box {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    @media (min-width: 769px) {
      width: 50%;
    }

    img {
      @include img();
      width: 300px;

      @media (min-width: 769px) {
        width: 50%;
      }
    }
  }

  .content-section {
    display: flex;
    width: 100%;
    align-items: flex-start;
    position: relative;

    @media (min-width: 769px) {
      align-items: center;

      width: 50%;
      padding: 0 50px;
    }

    .number-section {
      max-width: 50px;
      position: absolute;
      left: 0;
      top: -10px;

      @media (min-width: 769px) {
        max-width: 100px;
        position: relative;
      }
    }

    .text-section {
      padding-left: 10px;

      @media (min-width: 769px) {
        padding-left: 20px;
      }

      .head {
        @include font-weight-style-3(700, normal);
        font-size: 20px;
        color: $white;

        @media (max-width: 767px) {
          padding-left: 50px;
          font-size: 18px;
        }
      }

      ul {
        list-style-type: disc;
        margin: 10px 0 0 20px;

        @media (max-width: 767px) {
          margin: 10px 0 0 30px;
        }

        li {
          @include font-weight-style-3(400, normal);
          font-size: 16px;
          color: $white;

          @media (max-width: 767px) {
            font-size: 14px;
            padding: 0 10px;
          }

          span {
            font-weight: 700;
          }
        }
      }
    }
  }

  .border-line {
    position: relative;
    height: 100%;

    @media (max-width: 992px) {
      display: none;
    }

    &:before {
      content: '';
      width: 1px;
      height: 800px;
      position: absolute;
      background: $white;
      top: 0;
      left: 50%;

      @media (max-width: 1024px) {
        height: 600px;
      }
    }

    img {
      width: 80px;
    }

    .circle {
      left: 47%;

      @media (max-width: 1024px) {
        left: 46%;
      }
    }

    .circle-first {
      position: absolute;
      right: 0;
      top: -18px;
    }

    .circle-second {
      position: absolute;
      right: 0;
      top: 400px;

      @media (max-width: 1024px) {
        top: 270px;
      }
    }

    .circle-third {
      position: absolute;
      right: 0;
      top: 780px;

      @media (max-width: 1024px) {
        top: 570px;
      }
    }
  }


}

.edu-faq-section {
  background: #FFF3E7;
  padding: 80px 0;

  .head {
    @include black-big-head();
    margin-bottom: 40px;
    font-size: 36px;
    @include font-weight-style-3(700, normal);
  }

  .faqContainer {
    max-width: 683px;
    margin: 0 auto;
    width: 100%;
  }
}

.update-faq {
  .accordion {
    display: flex;
    flex-direction: column;

    .card {
      border: 1px solid $white;
      border-radius: 10px;
      background-color: $white;

      &.active {
        border: 1px solid #FEAA00;
      }

      .collapse {
        &.show {
          visibility: visible;
        }
      }

      &+.card {
        margin-top: 20px;

        .card-header {
          border-top: 0 solid rgba(0, 0, 0, .125);
        }
      }

      .card-header {
        padding: .75rem 2rem;
        background-color: $white;
        height: 90px;
        border: 0;

        @media (max-width: 767px) {
          height: 80px;
          padding: .75rem 1rem .75rem .75rem;
        }

        .btn-link {
          position: relative;
          padding: .375rem 0;
          color: #001E3B;
          font-size: 22px;
          @include font-weight-style(700, normal);
          text-decoration: none !important;
          text-align: left !important;
          height: 100%;

          @media (max-width: 767px) {
            font-size: 16px;
            line-height: 20px;
            padding: 0 2rem 0 0;
          }

          &::after {
            position: absolute;
            right: 0;
            top: 0;
            content: '';
            display: block;
            background: url(../../../public/img/educator-home/accordian-arrow.png) no-repeat center center;
            width: 70px;
            height: 70px;
            background-size: 100%;

            @media (max-width: 767px) {
              width: 50px;
              height: 50px;
              right: -10px;
            }
          }

          &:not(.collapsed)::after {
            position: absolute;
            right: 0;
            top: 0;
            content: '';
            display: block;
            background: url(../../../public/img/educator-home/accordian-arrow-open.png) no-repeat center center;
            width: 70px;
            height: 70px;
            background-size: 100%;

            @media (max-width: 767px) {
              width: 50px;
              height: 50px;
              right: -10px;
            }
          }
        }
      }

      .card-body {
        padding: 0 2rem .75rem 2rem;
        font-size: 18px;
        color: #34495E;
        position: relative;
        top: -5px;
        @include font-weight-style(600, normal);

        @media (max-width: 767px) {
          font-size: 14px;
          padding: 0 .75rem .75rem .75rem;
        }
      }
    }
  }
}

/* ==========================================================================
   faq-main-section
========================================================================== */
.faq-main-section {
  .faq-data {
    padding: 140px 0 50px 0;

    h3 {
      text-align: center;
      @include black-big-head();
      color: #001E3B;
      @include font-weight-style-3(600, normal);
      margin: 0 0 40px 0;

      @media (max-width: 992px) {
        font-size: 22px;
      }
    }

    p {
      font-size: 16px;
      @include font-weight-style-3(400, normal);

      &.big {
        font-size: 18px;
        @include font-weight-style-3(600, normal);

      }
    }

    .update-faq {
      margin-top: 30px;
    }
  }
}

/* ==========================================================================
   faq-main-section
========================================================================== */
.syllabus-section {
  padding: 70px 0 0 0;

  @media (max-width: 1024px) {
    padding: 60px 0 0 0;
  }

  .syllabus-section {
    width: 100%;
    margin-bottom: 50px;

    .container {
      @media (min-width: 1270px) {
        max-width: 1250px;
      }

      .head {
        max-width: 100%;
      }

      .nav-pills li.nav-item .nav-link {
        min-width: 120px;

        @media (max-width: 1200px) {
          min-width: 110px;
        }

        @media (max-width: 992px) {
          min-width: 80px;
        }

        @media (max-width: 767px) {
          min-width: 110px;
        }
      }
    }
  }
}

/* ==========================================================================
   exam page
========================================================================== */
.exam-date-section {
  overflow: hidden;

  .exam-container {
    padding: 120px 15px 50px 15px;
  }

  h3 {
    text-align: center;
    @include black-big-head();
    color: #001E3B;
    @include font-weight-style-3(600, normal);
    margin: 0 0 20px 0;

    @media (max-width: 992px) {
      font-size: 22px;
    }

    span {
      font-size: 20px;
      display: block;
      @include font-weight-style-3(500, normal);

      @media (max-width: 992px) {
        font-size: 18px;
      }
    }

    &.left {
      text-align: left;
      font-size: 26px;

      @media (max-width: 992px) {
        font-size: 20px;
      }

      span {
        font-size: 22px;

        @media (max-width: 992px) {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }

  }

  p {
    margin-bottom: 0;
    @include font-weight-style-3(400, normal);

    @media (max-width: 767px) {
      font-size: 14px;
    }

    &.big {
      font-size: 16px;
      @include font-weight-style-3(700, normal);

      @media (max-width: 992px) {
        font-size: 14px;
      }
    }
  }


  &.ribbon-right {
    position: relative;

    &::before {
      content: '';
      display: block;
      background: url(../img/ribon-right.png) no-repeat center;
      width: 275px;
      height: 365px;
      background-size: 140px;
      position: absolute;
      top: 120px;
      right: -66px;
      z-index: -1;

      @media (max-width: 1366px) {
        background-size: 110px;
        right: -80px;
      }

      @media (max-width: 1024px) {
        background-size: 80px;
        right: -97px;
        top: 17px;
      }


      @media (max-width: 767px) {
        width: 140px;
        height: 312px;
        background-size: 70px;
        right: 0;
        top: -138px;
      }
    }
  }

  &.ribbon-left {
    position: relative;

    &::after {
      content: '';
      display: block;
      background: url(../img/topic-ribon.png) no-repeat center;
      width: 275px;
      height: 365px;
      background-size: 140px;
      position: absolute;
      top: 30%;
      left: -66px;
      z-index: -1;

      @media (max-width: 1366px) {
        background-size: 110px;
        right: -80px;
      }

      @media (max-width: 1024px) {
        background-size: 80px;
        left: -97px;
        top: 10%;
      }


      @media (max-width: 767px) {
        width: 140px;
        height: 312px;
        background-size: 70px;
        right: 0;
        top: -138px;
      }
    }
  }
}


.table-container {
  overflow: auto;
  display: flex;
  margin: 40px 0;

  @media (min-width: 993px) {
    display: block !important;
  }

  .table-header-row {
    display: flex;
    flex-direction: column;
    background: #F4F4F4;
    padding: 15px;
    min-width: 180px;
    align-items: flex-start;

    @media (min-width: 769px) {
      flex-direction: row;
    }

    div {
      @include font-weight-style-3(600, normal);
      font-size: 14px;
      flex-shrink: 0;
      width: auto;
      height: 95px;
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (min-width: 769px) {
        font-size: 16px;
        height: auto;
        width: 25%;
        padding: 0;
        align-items: start;
        justify-content: flex-start;
      }
    }
  }

  .table-data-row {
    display: flex;
    background: #fff;
    flex-direction: column;
    padding: 15px;
    min-width: 200px;

    @media (min-width: 769px) {
      flex-direction: row;
    }

    &:nth-child(odd) {
      background-color: #FBFBFB;
    }

    div {
      @include font-weight-style-3(400, normal);
      font-size: 14px;
      flex-shrink: 0;
      width: auto;
      height: 95px;
      display: flex;
      align-items: center;

      @media (min-width: 769px) {
        font-size: 16px;
        height: auto;
        width: 25%;
        align-items: start;
      }

    }
  }
}

/* ==========================================================================
  educatour-feature
========================================================================== */
.educatour-feature {
  .img-box {
    img {
      max-width: 70% !important;
    }
  }
}