.checkBtn {
    @apply cursor-pointer flex items-center relative my-10  gap-3 font-medium
}

.checkLabel{
    @apply flex items-center w-full pl-10;
}

.checkBtn input:checked~.checkLabel {
    @apply transition duration-300;
}

.checkLabel::before {
    @apply w-5 h-5 absolute left-[5px]  outline outline-[2px] outline-[#C2C2C2] rounded-[5px] bg-white;
    content: "";
}

.checkLabel::after {
    @apply absolute left-[5px] w-5 h-5 transition duration-300 rounded-sm text-white flex items-center justify-center;
    content: "✓";
}

.checkBtn input:checked~.checkLabel::before {
    @apply outline-[#E74C3C];
}

.checkBtn input:checked~.checkLabel::after {
    @apply bg-[#E74C3C] transition duration-300
}