@-webkit-keyframes cardAnim {

  0%,
  to {
    -webkit-transform: translate3d(0, -3px, 0);
    transform: translate3d(0, -3px, 0);
  }

  50% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes cardAnim {

  0%,
  to {
    -webkit-transform: translate3d(0, -3px, 0);
    transform: translate3d(0, -3px, 0);
  }

  50% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@-webkit-keyframes cardAnimRvrt {

  0%,
  to {
    -webkit-transform: translate3d(0, 3px, 0);
    transform: translate3d(0, 3px, 0);
  }

  50% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes cardAnimRvrt {

  0%,
  to {
    -webkit-transform: translate3d(0, 3px, 0);
    transform: translate3d(0, 3px, 0);
  }

  50% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}


/* ==========================================================================
   school-back
========================================================================== */
.school-back {
  height: 700px;
  padding: 200px 20px 20px 20px;
  background-color: #FAB467;
  position: relative;

  @media (min-width: 1400px) {
    min-height: 750px;
  }

  @media (max-width: 992px) {
    height: auto;
    padding: 145px 20px 50px 20px;
  }

  .container {
    min-height: 100%;
    display: flex;
  }

  &::before {
    content: '';
    background: url(../../../public/img/parent-home/parent-banner-img.png) no-repeat bottom left;
    background-size: 100%;
    width: 400px;
    height: 411px;
    position: absolute;
    bottom: 0;
    left: 0;

    @media (max-width: 1400px) {
      width: 300px;
      height: 300px;
    }

    @media (max-width: 992px) {
      width: 270px;
    }
  }

  h3 {
    @include black-big-head();
    @include font-weight-style-3(600, normal);

    @media (max-width: 1024px) {
      font-size: 32px;
    }

    @media (max-width: 992px) {
      text-align: center;
      margin-top: 30px;
    }

    @media (max-width: 767px) {
      font-size: 22px;
    }

  }

  p {
    @include p();
    font-size: 18px;
    @include font-weight-style-3(400, normal);

    @media (max-width: 992px) {
      font-size: 15px;
      text-align: center;
    }

    &.bold {
      font-size: 22px;
      margin-top: 30px;

      @media (max-width: 992px) {
        font-size: 20px;
      }
    }
  }

  .btn-inline {
    margin-top: 30px;

    @media (max-width: 992px) {
      justify-content: center;
      flex-direction: column;

      .btn+.btn {
        margin: 10px 0 0 0;
      }
    }

    .btn {
      border-radius: 20px !important;
      height: 56px;
      font-size: 18px;
      min-width: 260px;
      padding: 10px;

      &.blue-btn {
        min-width: 260px;
      }

      @media (max-width: 992px) {
        min-width: 300px !important;
        @include font-weight-style-3(700, normal);
      }

      @media (max-width: 640px) {
        min-width: 100% !important;
        font-size: 16px;
      }
    }


  }

  .row {
    min-height: 100%;
    display: flex;
    align-items: center;

    .order-change {
      @media (max-width: 992px) {
        order: 1;
      }
    }
  }

  .educator-img {
    max-width: 500px;
    margin: 0 auto;
    display: block;
    position: relative;

    @media (max-width: 568px) {
      width: 300px;
    }

    .educator-banner {
      width: 500px;

      @media (max-width: 568px) {
        width: 100%;
      }
    }

    .card-1 {
      position: absolute;
      left: 27px;
      top: 80px;
      width: 180px;
      height: auto;

      @media (max-width: 568px) {
        width: 90px;
      }
    }

    .card-2 {
      position: absolute;
      right: 10px;
      top: 135px;
      width: 187px;
      height: auto;

      @media (max-width: 568px) {
        width: 100px;
        top: 80px;
      }
    }

    .card-3 {
      position: absolute;
      left: 100px;
      top: 225px;
      width: 146px;
      height: auto;

      @media (max-width: 568px) {
        width: 90px;
        top: 150px;
        left: 60px;
      }
    }

    .card-4 {
      position: absolute;
      right: 80px;
      bottom: 188px;
      width: 145px;
      height: auto;

      @media (max-width: 1024px) {
        right: 80px;
        bottom: 163px;
      }

      @media (max-width: 568px) {
        width: 80px;
        bottom: 100px;
        right: 50px;
      }
    }
  }
}

.school-practido {
  h4 {
    min-height: 70px;
    padding: 0 10px;
    @include font-weight-style-3(600, normal);

    @media (max-width: 767px) {
      min-height: auto;
      padding: 0;
    }
  }

  .img-box {
    position: relative;

    .sub-image {
      position: absolute;
      top: 0;
      left: 0;

      &.first-img {
        top: 90px;
        left: 66px;
        width: 25px;

        @media (max-width: 1024px) {
          left: 40px;
        }

        @media (max-width: 992px) {
          left: 10px;
        }
      }

      &.sec-img {
        top: 38px;
        left: -25px;
        width: 30px;

        @media (max-width: 1024px) {
          left: -50px;
        }

        @media (max-width: 992px) {
          left: -80px;
        }
      }

      &.third-img {
        top: 46px;
        left: 158px;
        width: 40px;

        @media (max-width: 1024px) {
          left: 140px;
        }

        @media (max-width: 992px) {
          left: 110px;
        }
      }

      &.fourth-img {
        top: 53px;
        left: 217px;
        width: 30px;

        @media (max-width: 1024px) {
          left: 190px;
        }

        @media (max-width: 992px) {
          left: 170px;
        }
      }

      &.fifth-img {
        top: 88px;
        left: 234px;
        width: 35px;

        @media (max-width: 1024px) {
          left: 210px;
        }

        @media (max-width: 992px) {
          left: 180px;
        }
      }

      &.practido-2-first-img {
        top: 13px;
        left: 135px;
        width: 32px;

        @media (max-width: 1024px) {
          left: 120px;
        }

        @media (max-width: 992px) {
          left: 90px;
        }
      }

      &.practido-2-sec-img {
        top: 36px;
        left: 181px;
        width: 45px;

        @media (max-width: 1024px) {
          left: 150px;
        }

        @media (max-width: 992px) {
          left: 130px;
        }
      }

      &.practido-3-first-img {
        top: 112px;
        left: 65px;
        width: 22px;

        @media (max-width: 1024px) {
          left: 40px;
        }

        @media (max-width: 992px) {
          left: 10px;
        }
      }

      &.practido-3-sec-img {
        top: 42px;
        left: 104px;
        width: 28px;

        @media (max-width: 1024px) {
          left: 80px;
        }

        @media (max-width: 992px) {
          left: 45px;
        }
      }

      &.practido-3-third-img {
        top: 46px;
        left: 215px;
        width: 23px;

        @media (max-width: 1024px) {
          left: 185px;
        }

        @media (max-width: 992px) {
          left: 155px;
        }
      }

      &.practido-3-fourth-img {
        top: 110px;
        left: 255px;
        width: 22px;

        @media (max-width: 1024px) {
          left: 225px;
        }

        @media (max-width: 992px) {
          left: 195px;
        }
      }
    }
  }
}

/* ==========================================================================
   conduct-online-exam
========================================================================== */

.conduct-online-exam {
  background-color: #151945;
  background: url(../img/new-home/header-bg.png) no-repeat top center #151945;
  min-height: 700px;
  padding: 80px 0 80px 0;
  background-size: 100%;
  position: relative;

  h2 {
    @include font-weight-style-3(600, normal);
    font-size: 36px;
    line-height: normal;
    letter-spacing: -0.3px;
    color: #fff;
    max-width: 280px;
  }

  p {
    color: #fff;
    line-height: 30px;
    margin-bottom: 20px;
    @include font-weight-style-3(400, normal);

    span {
      @include font-weight-style-3(700, normal);

    }
  }

  .demo-btn {
    background-color: #fff;
    border-radius: 10px;
    font-size: 16px;
    height: 40px;
    width: 200px;
    @include font-weight-style-3(600, normal);
    box-shadow: 0px 4px 0px 0px #BDC3C7;
  }

  .card-box {
    background: #2B2EA0;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 30px;
    position: relative;

    &.first-box {
      margin-top: 100px;
    }

    .conect-strip {
      position: absolute;
      height: 30px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #2B2EA0;
      left: 0;
      bottom: -30px;



      .first-strip {
        height: 100%;
        border-radius: 0 20px 20px 0px;
        width: 31%;
        background: #151945;
      }

      .secount-strip {
        height: 100%;
        border-radius: 20px 0 0 20px;
        width: 61%;
        background: #151945;

      }

      &.right-side {
        left: auto;
        right: -30px;
        top: 0;
        height: 100%;
        width: 30px;
        flex-direction: column;

        .first-strip {
          width: 100%;
          height: 35%;
          border-radius: 0 0 20px 20px;
        }

        .secount-strip {
          width: 100%;
          height: 60%;
          border-radius: 20px 20px 0 0;
          background: #151945;

        }
      }

      &.left-side {
        left: -30px;
        top: 0;
        height: 100%;
        width: 30px;
        flex-direction: column;

        .first-strip {
          width: 100%;
          height: 58%;
          border-radius: 0 0 20px 20px;
        }

        .secount-strip {
          width: 100%;
          height: 35%;
          border-radius: 20px 20px 0 0;
          background: #151945;

        }
      }

      &.bottom-right-side {
        .first-strip {
          width: 60%;
        }

        .secount-strip {
          width: 32%;
        }
      }
    }

    h3 {
      color: #fff;
      font-size: 20px;
      margin-bottom: 15px;
      @include font-weight-style-3(700, normal);
      margin-top: 20px;
    }

    p {
      color: #fff;
      line-height: 24px;
      margin-bottom: 20px;
      @include font-weight-style-3(300, normal);

    }
  }
}

/* ==========================================================================
 moniter-time
========================================================================== */

.moniter-time {
  background-color: #FFFAF0;
  min-height: 750px;
  position: relative;
  overflow: hidden;

  .container {
    padding: 80px 0 80px 0;

    @media (max-width: 992px) {
      padding: 50px 15px 50px 15px;
    }

  }


  h2 {
    @include font-weight-style-3(600, normal);
    font-size: 36px;
    line-height: normal;
    letter-spacing: -0.3px;
    color: #34495E;
    text-align: center;
    margin-bottom: 50px;
    padding: 0 100px;

    @media (max-width: 1024px) {
      padding: 0;
    }

    @media (max-width: 992px) {
      font-size: 22px;
    }

    span {
      text-align: center;
      color: #34495E;
      line-height: 30px;
      display: block;
      font-size: 18px;
      @include font-weight-style-3(400, normal);

      @media (max-width: 992px) {
        font-size: 16px;
      }

    }
  }


  .img-box {
    position: absolute;
    width: 100%;

    @media (max-width: 992px) {
      position: relative;
      margin: 0 auto;
    }

    img {
      text-align: center;
      max-width: 550px;
      margin: auto;

      @media (max-width: 1366px) {
        max-width: 600px;
      }

      @media (max-width: 1024px) {
        max-width: 500px;
      }

      @media (max-width: 992px) {
        max-width: 100%;
      }
    }
  }

  .inner-container {
    position: relative;
    padding-bottom: 150px;

    @media (min-width: 1200px) {
      left: -20px;
    }

    @media (min-width: 1450px) {
      left: -60px;
    }

    @media (max-width: 992px) {
      padding-bottom: 50px;
    }

    .row-margin {
      margin-top: 200px;

      @media (min-width: 1500px) {
        margin-top: 260px;
      }

      @media (max-width: 992px) {
        margin-top: 40px;
      }
    }

    .card-row {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;

      .card-animation {
        @media (max-width: 992px) {
          width: 100%;
        }
      }
    }

    .innter-card {
      padding: 20px 20px;
      background: #FFCDCD;
      border-radius: 15px;
      position: relative;
      width: 280px;
      z-index: 2;
      -webkit-transition: 0.1s ease-in-out;
      -o-transition: 0.1s ease-in-out;
      transition: 0.1s ease-in-out;
      -webkit-animation: cardAnim ease-in-out infinite 1.2s;
      animation: cardAnim ease-in-out infinite 1.2s;

      @media (max-width: 992px) {
        position: relative;
        width: 100%;
        padding: 40px 20px;
      }

      p {
        color: #34495E;
        margin-bottom: 20px;
        font-size: 14px;
        margin: 0;
        @include font-weight-style-3(400, normal);

        span {
          @include font-weight-style-3(700, normal);

        }
      }

      &::before {
        content: '';
        display: block;
        background: url(../../../public/img/school-home/dots.png) no-repeat bottom left;
        width: 150px;
        height: 150px;
        right: -100px;
        position: absolute;
        background-size: 80%;
        z-index: 1;

        @media (max-width: 992px) {
          display: none;
        }
      }


      &.right-side-card {
        top: 50px;
        left: auto;
        background-color: #FFBE56;
        -webkit-animation: cardAnimRvrt ease-in-out infinite 1.2s;
        animation: cardAnimRvrt ease-in-out infinite 1.2s;

        @media (max-width: 1366px) {
          right: -50px;
        }

        @media (max-width: 1024px) {
          right: 0;
        }

        @media (max-width: 992px) {
          top: 20px;
        }

        &::before {
          content: '';
          display: block;
          background: url(../../../public/img/school-home/dots.png) no-repeat top left;
          right: auto;
          left: -40px;
          top: -62px;
          width: 150px;
          height: 150px;
          background-size: 80%;

          @media (max-width: 992px) {
            display: none;
          }
        }
      }

      &.bottom-left-card {
        left: 20px;
        background-color: #FFEF72;
        -webkit-animation: cardAnimRvrt ease-in-out infinite 1.2s;
        animation: cardAnimRvrt ease-in-out infinite 1.2s;

        @media (max-width: 992px) {
          left: 0;
          margin-bottom: 20px;
        }
      }

      &.bottom-right-card {
        background-color: #BE95FF;

        &::before {
          content: '';
          display: block;
          background: url(../../../public/img/school-home/dots.png) no-repeat top left;
          right: auto;
          left: -40px;
          top: -62px;
          width: 150px;
          height: 150px;
          background-size: 80%;
        }
      }
    }

    .innter-box {
      width: 100%;

      @media (max-width: 1024px) {
        width: 250px;
      }
    }
  }
}


/* ==========================================================================
 save-time-section
========================================================================== */

.save-time-section {
  background-color: #246CFF;
  padding: 0;

  .top-head {
    margin: 0 auto 150px !important;

    @media (max-width: 992px) {
      margin: 0 auto 100px !important;
    }

    h3 {
      color: $white;
    }

    p {
      color: $white;
      @include font-weight-style-3(400, normal);
    }
  }

  .container {
    &.main-container {
      position: relative;
      z-index: 2;
      padding: 60px 15px 60px 15px;

      .dashed-line {
        position: absolute;
        top: 8%;
        z-index: -1;
        left: 0;
        right: 0;
        margin: auto;
        max-width: 730px;
        overflow: hidden;
        height: 100%;

        @media (max-width: 992px) {
          display: none;
        }
      }

      .img-box {
        display: flex;
        justify-content: center;
      }

      h3,
      p {
        @media (max-width: 767px) {
          text-align: center;
        }
      }
    }

    .row {
      margin-bottom: 80px;

      @media (min-width: 992px) {
        margin-top: 0;
        margin-bottom: 280px;

        &.row-2 {
          margin-bottom: 460px;
        }

        &.row-3 {
          margin-bottom: 400px;
        }

        &.row-4 {
          margin-bottom: 600px;
        }

        &.row-5 {
          margin-bottom: 500px;
        }

        &.row-6 {
          margin-bottom: 500px;
        }

        &.row-7 {
          margin-bottom: 600px;
        }

        &.row-8 {
          margin-bottom: 400px;
        }


        &.row-10 {
          margin-bottom: 0;
        }

        &+.row {
          margin-top: 0;
        }
      }

      h3,
      p {
        color: #fff;
      }

      .content {
        background-color: #246CFF;
      }

      .img-box {

        img {
          width: 60%;
          background-color: #246CFF;
        }
      }
    }



  }


}

/* ==========================================================================
 reward-active
========================================================================== */
.reward-active {
  height: 550px;
  overflow: hidden;

  @media (min-width: 1500px) {
    height: 600px;
  }

  @media (max-width: 992px) {
    height: auto;
  }

  .container-fluid {
    padding: 0;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .left-section {
    background-color: #FF00B9;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    z-index: 2;
    overflow: hidden;

    @media (max-width: 992px) {
      height: 250px;
    }

    h3 {
      @include black-big-head();
      @include font-weight-style-3(600, normal);
      max-width: 50px;
      font-size: 45px;
      color: $white;
      margin: 0 auto;
      max-width: 250px;

      @media (max-width: 992px) {
        text-align: center;
        margin-top: 30px;
      }

      @media (max-width: 767px) {
        font-size: 22px;
      }

    }

    .floating-img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;

      @media (max-width: 992px) {
        position: absolute;
      }

      .reward-0 {
        position: absolute;
        top: 45%;
        left: 0;
        width: 250px;
        height: 190px;

        @media (max-width: 992px) {
          top: 35%;
          width: 180px;
          height: 150px;
        }
      }

      .reward-1 {
        position: absolute;
        bottom: 10%;
        top: auto;
        left: 0;
        width: 40px;
      }

      .reward-2 {
        position: absolute;
        top: 21%;
        left: 15%;
        width: 50px;
        opacity: 0.5;
      }

      .reward-3 {
        position: absolute;
        top: 10%;
        left: 15%;
        width: 35px;

        @media (max-width: 992px) {
          top: 21%;
          left: 5%;
        }
      }

      .reward-4 {
        position: absolute;
        top: 2%;
        left: 0;
        width: 40px;

        @media (max-width: 992px) {
          top: 6%;
          width: 30px;
        }
      }

      .reward-6 {
        position: absolute;
        top: 20%;
        left: 75%;
        width: 40px;

        @media (max-width: 992px) {
          top: 8%;
          left: 60%;
          width: 18px;
        }

      }

      .reward-7 {
        position: absolute;
        top: 0;
        left: 75%;
        width: 40px;

        @media (max-width: 992px) {
          top: 6%;
          width: 30px;
        }
      }

      .reward-9 {
        position: absolute;
        top: 28%;
        right: 10%;
        left: auto;
        width: 60px;
      }

      .reward-10 {
        position: absolute;
        top: 40%;
        left: 68%;
        width: 130px;

        @media (max-width: 992px) {
          display: none;
          ;
        }
      }
    }

  }

  .right-section {
    background-color: #141736;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    padding: 0;

    .shimmer-wrapper {
      height: 55px;
      background-color: #141736;
    }

    .col-md-6 {
      padding: 40px 50px;

      &.blue-back {
        background-color: #131847;

        .shimmer-wrapper {
          background-color: #131847;
        }
      }

      &:nth-child(3) {
        @media (max-width: 992px) {
          background-color: #141736;

          .shimmer-wrapper {
            background-color: #141736;
          }
        }
      }

      &:nth-child(4) {
        @media (max-width: 992px) {
          background-color: #131847;

          .shimmer-wrapper {
            background-color: #131847;
          }
        }
      }
    }

    h4 {
      letter-spacing: -0.3px;
      color: $white !important;
      @include black-big-head();
      font-size: 20px;
      margin: 20px 0 0 0;
      @include font-weight-style-3(700, normal);

      @media (max-width: 992px) {
        font-size: 18px;
      }
    }

    p {
      letter-spacing: -0.3px;
      color: $white !important;
      margin: 10px 0 0 0;
      font-size: 16px;
      @include font-weight-style-3(400, normal);

      @media (max-width: 992px) {
        font-size: 14px;
      }

      @media (max-width: 1024px) {
        font-size: 12px;
      }
    }



    img {
      width: 55px;
      height: auto;
      margin: 0 auto;
      text-align: center;

      @media (min-width: 1440px) {
        width: 50px;
        height: auto;
      }

      @media (max-width: 1024px) {
        width: 35px;
        height: auto;
      }

      @media (max-width: 992px) {
        width: 50px;
        height: auto;
      }
    }
  }
}