/* ==========================================================================
   School Registration
========================================================================== */
.school-registration {
  overflow: hidden;
  background: url(../../../public/img/new-home/common-bg.png) no-repeat top center #FFF3E7;
  background-size: 100%;

  .container-fluid {
    padding: 140px 0 0 0;

    @media (max-width: 992px) {
      padding: 0 0 40px 0;
    }

    h3 {
      @include black-big-head();
      @include font-weight-style-3(600, normal);
      margin: 0 0 20px 0;
      font-size: 26px;
      line-height: 34px;

      @media (max-width: 992px) {
        font-size: 20px;
      }

      span {
        font-size: 22px;
        display: block;
        @include font-weight-style-3(400, normal);

        @media (max-width: 992px) {
          font-size: 18px;
        }
      }
    }

    p {
      @include p();
      margin-bottom: 0;
      @include font-weight-style-3(400, normal);
      font-size: 14px;

      &.big {
        font-size: 16px;
        @include font-weight-style-3(700, normal);
      }

      a {
        color: $blue;
        text-decoration: underline;
        @include font-weight-style-3(600, normal);
      }
    }

    .btn-red-register-btn {
      border-radius: 47px;
      text-transform: uppercase;
      @include font-weight-style-3(600, normal);
    }

  }


  .form-container {
    padding: 0;
    margin-bottom: 50px;
    position: relative;
    min-height: 500px;

    &::before {
      @media (min-width: 993px) {
        display: block;
        content: '';
        width: 50%;
        height: 550px;
        position: absolute;
        top: 0;
        right: 0;
        border-bottom-left-radius: 100px;
        background: url(../../../public/img/new-home/common-bg.png) no-repeat top left #080B68;
        background-size: 100%;
      }
    }

    .row {
      &.order-change {
        @media (max-width: 992px) {
          flex-direction: column-reverse;
        }
      }
    }

    .left-item {
      @media (max-width: 992px) {
        position: unset;
      }

      .form-data {
        padding: 0 20px;

        h4 {
          @include black-big-head();
          @include font-weight-style-3(600, normal);
          font-size: 16px;
        }

        @media (min-width: 993px) {
          width: 80%;
          margin-left: auto;
          margin-right: auto;
        }

        @media (min-width: 1700px) {
          width: 65%;
        }

        .mobile-header {
          @media (max-width: 992px) {
            text-align: center;
          }

          .button-group {
            display: flex;
            align-items: center;
            border-radius: 47px;
            margin-bottom: 40px;
            width: 100%;

            @media (max-width: 992px) {
              justify-content: center;
            }

            .primary-btn {
              background-color: $white !important;
              min-width: 50%;
              font-size: 16px;
              border: 1px solid #2B2E9F;
              color: $black-drak !important;
              box-shadow: none !important;
              padding: 5px 10px;

              &:first-child {
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
              }

              &:last-child {
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
              }

              &.active {
                background-color: #FCB131 !important;
                border: 1px solid #FCB131;
              }
            }
          }
        }

        .full-row {
          width: 105% !important;
        }

      }
    }

    .right-item {
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 1;
      padding: 0;

      @media (max-width: 992px) {
        background: url(../img/new-home/header-bg.png) no-repeat top center #1C214D;
        min-height: 550px;
        padding: 140px 0 0 0;
        margin-bottom: 0;
        background-size: 150%;
        position: relative;
        border-bottom-left-radius: 80px;
        border-bottom-right-radius: 80px;
        margin-bottom: 40px;
      }

      img.banner-cloud {
        display: block;
        margin: 0 auto;
        width: 400px;

        @media (max-width: 640px) {
          width: 310px;
        }
      }

      .reg-data {
        background-color: $white;
        margin: 40px 40px 0 30px;
        width: calc(100% - 70px);
        padding: 50px 40px;
        position: relative;

        .full-data {
          display: none;
        }

        .full-data.show {
          display: block;

          @media (min-width: 993px) {
            display: block !important;
          }

          ol,
          ul {
            li {
              font-size: 14px;
            }
          }
        }

        @media (min-width: 1440px) {
          max-width: 800px;
          margin: 40px auto 0;
        }

        @media (max-width: 992px) {
          width: calc(100% - 60px);
          padding: 40px 15px;
          margin: 60px 30px 40px 30px;

        }

        h3 {
          font-size: 18px;
          margin-bottom: 0;
          color: $black;

          @media (max-width: 992px) {
            color: $black;
            font-size: 16px;
            line-height: 20px;
            padding-right: 20px;
          }
        }

        p {
          @media (max-width: 992px) {
            color: $black;
            font-size: 14px;
          }
        }

        .mobile-heading {
          @media (min-width: 993px) {
            pointer-events: none;
          }

          h3 {
            @media (max-width: 992px) {
              display: flex;

              &::after {
                content: '';
                display: block;
                background: url(../../../public/img/reg/black-right-arrow.png) no-repeat top right;
                width: 11px;
                height: 19px;
                position: absolute;
                right: 25px;
                top: 42px;
                background-size: 85%;
              }
            }
          }

          &.active {
            @media (max-width: 992px) {
              h3 {
                &::after {
                  transform: rotate(90deg);
                }
              }
            }
          }
        }

        ul.bullet-list-data {
          margin: 10px 0 20px 0;

          li {
            @media (max-width: 992px) {
              font-size: 14px;
            }
          }
        }

        ol.order-list-data {
          li {
            @media (max-width: 992px) {
              font-size: 14px;
            }
          }
        }

        &::after {
          content: '';
          display: block;
          background: url(../../../public/img/black-hang-tag.png) no-repeat top left;
          background-size: 100%;
          height: 130px;
          width: 29px;
          position: absolute;
          top: -78px;
          right: 50px;

          @media (max-width: 767px) {
            right: 30px;
          }

        }

        &::before {
          content: '';
          display: block;
          background: url(../../../public/img/black-hang-tag.png) no-repeat bottom left;
          background-size: 100%;
          height: 130px;
          width: 29px;
          position: absolute;
          top: -120px;
          left: 50px;

          @media (max-width: 767px) {
            top: -119px;
            left: 30px;
          }
        }
      }
    }

    .school-logo {
      background-color: $white;
      border: 1px dashed #C2C2C2;
      width: 100%;
      padding: 15px;
      min-height: 150px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .upload-wrapper {
        position: relative;
        max-width: 400px;

        img {
          margin: 0 auto;
        }

        input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          opacity: 0;
        }
      }

      p {
        @include p();
        margin: 0;
        @include font-weight-style-3(400, normal);

        &.big {
          font-size: 16px;
          @include font-weight-style-3(700, normal);
        }

        span {
          color: $primary-color !important;
        }
      }
    }

    .form-group {
      .error-txt {
        color: $primary-color;
        font-size: 12px;
        bottom: 0;
        margin-left: 0;
        position: relative;
        // display: none;
        @include font-weight-style(600, normal);
      }
    }

    .otp-wrapper {

      p {
        font-size: 14px;

        @media (min-width: 993px) {
          margin-bottom: 20px;
        }
      }

      .get-link {
        margin-top: 20px;
        font-size: 14px;
        @include font-weight-style-3(500, normal);

        .resend-code {
          text-decoration: underline;
          color: $light-blue;
          @include font-weight-style-3(600, normal);
        }
      }

      h3 {
        @media (max-width: 992px) {
          font-size: 16px;
          line-height: 25px;
          margin-bottom: 0;
        }
      }

      .logo-box {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        img {
          width: 120px;

          @media (max-width: 640px) {
            width: 70px;
          }
        }



        h4 {
          @include black-big-head();
          @include font-weight-style-3(600, normal);
          font-size: 22px;
          margin-left: 15px;
          max-width: 300px;

          @media (max-width: 640px) {
            font-size: 16px;
          }

          span {
            display: block;
            font-size: 16px;
            @include font-weight-style-3(400, normal);

            @media (max-width: 640px) {
              font-size: 14px;
            }
          }
        }
      }
    }

    .form-outer-container {
      background: $yellow-bg;
      padding: 15px;
      border-radius: 15px;
      margin-bottom: 10px;

      .box-heading {
        @include font-weight-style(700, normal);
        font-size: 18px;
        margin-bottom: 10px;
      }
    }
  }

  &.sign-in-account {
    .form-container {
      &::before {
        content: '';
        display: block;
        height: 650px;
        border-bottom-left-radius: 0;

        @media (max-width: 992px) {
          height: auto;
        }
      }

      .right-item {
        @media (max-width: 992px) {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }
}

.olympiad-exams {
  // @media (min-width: 1350px) {
  //   .container {
  //     max-width: 1300px;
  //   }
  // }

  .col-data {
    padding: 0 40px;
  }

  .img-box {
    @media (max-width: 992px) {
      margin: 0;
    }
  }

  p {
    @include p();
    min-height: auto;
    font-size: 14px;

    @media (max-width: 992px) {
      text-align: left;
    }
  }

  h3 {
    font-size: 32px;

    @media (max-width: 992px) {
      font-size: 22px;
      line-height: 28px;
    }

    span {
      font-size: 18px;

      @media (max-width: 992px) {
        font-size: 16px;
      }
    }


  }

  h4 {
    font-size: 16px;

    @media (max-width: 992px) {
      text-align: left;
    }
  }

  .btn-inline {
    margin-top: 20px;
    align-items: center;

    @media (max-width: 992px) {
      justify-content: flex-start;
    }

    .link-btn {
      margin-top: 0;
      text-decoration: underline;
    }
  }

  .col-data {
    @media (max-width: 992px) {
      margin-bottom: 40px;
      padding: 0 25px;
    }

    &::after {
      content: '';
      display: block;
      height: 75%;
      position: absolute;
      right: 3px;
      top: auto;
      bottom: 0;
      border-right: 1px solid #C4C4C4;

      @media (max-width: 992px) {
        display: none;
      }
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}

/* ==========================================================================
   School Registration sucess
========================================================================== */
.registration-success {
  padding: 150px 0 50px 0;
  position: relative;

  &.registration-banner {
    &::before {
      content: '';
      background: url(../img/new-home/header-bg.png) no-repeat top center #080B68;
      min-height: 700px;
      background-size: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }
  }

  .reg-img {
    margin: 0 auto 40px;
    width: 500px;

    @media (max-width: 640px) {
      width: 300px;
    }
  }

  .success-container {
    z-index: 1;
    position: relative;
    background-color: $white;
    padding: 40px;

    @media (max-width: 767px) {
      margin-top: 60px;
      padding: 40px 20px;
    }

    &::after {
      content: '';
      display: block;
      background: url(../../../public/img/black-hang-tag.png) no-repeat top left;
      background-size: 100%;
      height: 130px;
      width: 29px;
      position: absolute;
      top: -78px;
      right: 50px;

      @media (max-width: 767px) {
        right: 30px;
      }

    }

    &::before {
      content: '';
      display: block;
      background: url(../../../public/img/black-hang-tag.png) no-repeat bottom left;
      background-size: 100%;
      height: 130px;
      width: 29px;
      position: absolute;
      top: -120px;
      left: 50px;

      @media (max-width: 767px) {
        top: -119px;
        left: 30px;
      }
    }

    h3 {
      @include font-weight-style-3(600, normal);
    }

    h2 {
      @include black-big-head();
      font-size: 18px;
      margin-bottom: 0;
      color: #001E3B;
      @include font-weight-style-3(600, normal);

      @media (max-width: 992px) {
        color: $black;
        font-size: 16px;
        line-height: 20px;
        padding-right: 20px;
      }
    }

    p {
      @include p();
      @include font-weight-style-3(400, normal);

      @media (max-width: 992px) {
        color: $black;
        font-size: 14px;
      }

      &.italic {
        @include font-weight-style-3(600, italic);
      }

      &.big {
        font-size: 24px;
        @include font-weight-style-3(500, normal);
        text-align: center;

        @media (max-width: 767px) {
          font-size: 20px;
        }
      }

      &.small {
        font-size: 14px;
        text-align: center;
      }

      &.bold {
        @include font-weight-style-3(600, normal);
      }
    }

    ul.bullet-list-data {
      margin: 10px 0 20px 5px;

      @media (max-width: 767px) {
        li {
          font-size: 14px;
        }
      }
    }

    ol.order-list-data {
      margin: 0;

      @media (max-width: 767px) {
        li {
          font-size: 14px;
        }
      }
    }

    a {
      color: #004EFD;
      @include font-weight-style-3(600, normal);
    }

    .blue-card {
      padding: 15px;
      min-height: 200px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 720px;

      @media (max-width: 640px) {
        width: 100%;
      }

      .logo-box {
        display: flex;
        align-items: center;

        @media (max-width: 640px) {
          flex-direction: column;
        }

        h4 {
          font-size: 18px;
          @include font-weight-style-3(600, normal);
          margin: 0 0 0 10px;

          @media (max-width: 640px) {
            margin: 10px 0 0 0;
          }

          span {
            display: block;
            font-size: 14px;
            @include font-weight-style-3(400, normal);
          }
        }

        img {
          max-width: 80px;
          height: auto;

          @media (max-width: 640px) {
            width: 70px;
          }
        }
      }

      .reg-code {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .para {
          width: 100%;

          @media (min-width: 767px) {
            width: calc(100% - 140px);
            padding-right: 10px;
          }
        }

        a {
          word-wrap: break-word;
        }

        .icons {
          @media (max-width: 767px) {
            margin-top: 10px;
          }
        }
      }

    }
  }
}

.school-logo-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 990px;
  margin: 0 auto;
  padding: 0 15px;

  .img-box {
    width: 100px;
    height: 100px;
    overflow: hidden;
    margin-right: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .school-details {
    .school-name {
      @include font-weight-style(700, normal);
      font-size: 20px;
    }

    .school-address {
      @include font-weight-style(400, normal);
      font-size: 14px;
    }
  }
}

.step3 {
  @media (max-width: 767px) {
    padding: 0;
  }

  h3 {
    @include black-big-head();
    margin: 10px 0;
    font-size: 22px;
    @include font-weight-style-3(600, normal);

    span {
      display: block;
      font-size: 14px;
      @include font-weight-style-3(400, normal);
    }
  }

  .participant-count {
    span {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  .subscribe-check {
    .checkBtn {
      margin: 0 !important;
    }
  }

  .payment-successful-card {
    @extend .yellow-box;
    padding: 30px 10px;
    display: flex;
    justify-content: center;

    .center-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }


    img {
      width: 100px;
    }

    h4 {
      font-size: 20px;
      margin: 10px 0;
      @include font-weight-style-3(600, normal);
    }



    p {
      @include p();
      @include font-weight-style-3(500, normal);
    }

  }
}


.take-photo {
  &::before {
    content: '';
    display: block;
    background: url(../../../public/img/cam-icon.png) no-repeat;
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }
}