/* ==========================================================================
   step 1 2
========================================================================== */
.onboarding-step {
    h3 {
        font-size: 22px;
        margin-bottom: 40px;
        @include font-weight-style(700, normal);

        @media (max-width: 992px) {
            max-width: 350px;
        }

        @media (max-width: 992px) {
            max-width: 100%;
        }
    }

    .imp-inst {
        max-width: 550px;

        @media (max-width: 1024px) {
            max-width: 450px;
        }

        @media (max-width: 992px) {
            max-width: 100%;
        }
    }
}

.onboarding-head {
    margin-bottom: 50px;

    .item-left {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;

        .step-btn {
            color: $black;
            font-size: 18px;
            @include font-weight-style(700, normal);
            outline: 0;
            box-shadow: none;

            @media (max-width: 640px) {
                font-size: 14px;
            }

            span {
                width: 28px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #8D8D99;
                font-size: 12px;
                margin-right: 10px;
                border-radius: 50%;
                background: #E1E1E6;
                @include font-weight-style(400, normal);

                @media (max-width: 640px) {
                    width: 22px;
                    height: 22px;
                }
            }

            &.active {
                color: $light-blue;

                span {
                    color: $white;
                    background: $light-blue;
                }
            }

            &+.btn {
                margin-left: 40px;
            }
        }

    }

    .item-right {
        display: flex;
        justify-content: flex-end;

        .log-email {
            font-size: 20px;
            @include font-weight-style(700, italic);

            span {
                color: $light-blue;
            }

            @media (max-width: 767px) {
                font-size: 16px;
                margin-top: 10px;
            }
        }
    }
}

.onboarding-second-step {
    margin-bottom: 20px;

    .onboarding-form {
        @media (max-width: 767px) {
            padding: 20px;
        }
    }
}

.capture-modal {
    position: relative;
    z-index: 10;

    .inner-div {
        padding: 0 20px;
        top: 25px;
    }

    .img-capture-modal {
        height: 550px;
        margin: 50px 0 20px 0;

        @media (min-width: 1440px) {
            height: 600px;
        }

        @media (max-width: 767px) {
            margin: 0;
            height: 500px;
        }

        .capture-body {
            @media (max-width: 767px) {
                width: 310px;
                height: 310px;
                margin: 0 auto;
            }
        }

        .camera-body {
            @media (max-width: 767px) {
                width: 350px;
                height: 350px;
            }
        }
    }
}

.summary-card {
    @media (max-width: 1366px) {
        max-width: 400px;
    }

    @media (max-width: 992px) {
        position: relative !important;
        right: auto !important;
        top: 0 !important;
        max-width: 100%;
    }

    .green-row {
        background: #16DF55;
        padding: 10px 5px;
        color: #003C13;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include font-weight-style-1(700, normal);

        @media (min-width: 1400px) {
            font-size: 14px;
        }

        img {
            width: 25px;
            height: 25px;
            margin-right: 10px;
        }
    }

    &.for-mobile {
        @media (min-width: 993px) {
            display: none;
        }

        .yellow-box {
            display: none;
        }
    }

    &.desktop-view {
        img {
            @media (max-width: 992px) {
                display: none;
            }
        }
    }
}

.subject-card {
    @media (max-width: 640px) {
        width: 80px !important;
        height: 80px !important;
        padding: 20px;
    }
}

.payment-summary-card {
    @media (max-width: 767px) {
        width: 100% !important;
    }

    .inner-box {
        @media (max-width: 767px) {
            padding: 15px !important;
        }
    }

    .card-detail {
        @media (max-width: 767px) {
            padding: 10px 10px !important;
        }
    }
}

/* ==========================================================================
   success-container
========================================================================== */
.success-container {
    width: 100%;

    .onboarding-head {
        margin-bottom: 0;
    }

    .success-wrapper {
        text-align: center;
        margin: 0 auto;

        img {
            margin: 0 auto 20px;
            width: 150px;
        }

        h3 {
            margin-bottom: 20px;
            color: #1E1E1E;
            font-size: 28px;
            letter-spacing: -1px;
            @include font-weight-style-1(700, normal);
        }

        p {
            margin-bottom: 20px;
            color: #1E1E1E;
            font-size: 20px;
            @include font-weight-style-1(500, normal);

            span.bold {
                @include font-weight-style-1(700, normal);
            }

            @media (max-width: 767px) {
                font-size: 18px;
            }
        }
    }
}

/* ==========================================================================
   exam login
========================================================================== */
.exam-login {
    padding: 100px 0 50px 0;

    .container {
        flex-wrap: wrap;

        .card-box {
            width: 32%;

            @media (max-width: 1024px) {
                width: 48%;
            }

            @media (max-width: 767px) {
                width: 100%;
            }
        }
    }
}

.otp-main-card {
    .otp-card {
        input {
            width: 40px !important;
            height: 40px !important;
            padding: 5px !important;
            text-align: center;
        }
    }
}