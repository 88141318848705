/* ==========================================================================
   animation
	========================================================================== */
.zoom-in-zoom-out {
  animation: zoom-in-zoom-out 2s ease-out infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.4, 1.4);
  }

  100% {
    transform: scale(1, 1);
  }
}



.floating-animation {
  animation-name: floating-animation;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;

  &:nth-child(1),
  &:nth-child(5),
  &:nth-child(7) {
    @extend .zoom-in-zoom-out;
  }
}

@keyframes floating-animation {
  from {
    transform: translate(0, 0px);
  }

  65% {
    transform: translate(0, 20px);
  }

  to {
    transform: translate(0, -0px);
  }
}

.shimmer-wrapper {
  position: relative;
  display: inline-block;
  background-color: #1C223E;
  /* Ensure the background is black */
  overflow: hidden;
}

.shimmer-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: -150%;
  width: 150%;
  height: 100%;
  background: linear-gradient(90deg,
      rgba(28, 34, 62, 0) 0%,
      rgba(28, 34, 62, 0.5) 50%,
      rgba(28, 34, 62, 0) 100%);
  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  0% {
    left: -150%;
  }

  50% {
    left: 150%;
  }

  100% {
    left: 150%;
  }
}

.shimmer-wrapper img {
  display: block;
  width: 100%;
  height: auto;
}


.scrollToTopBtn {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 30px;
  line-height: 48px;
  width: 50px;
  position: fixed;
  bottom: 40px;
  right: 30px;
  z-index: 9999;
  opacity: 0;
  transform: translateY(100px);
  transition: all .5s ease;
}

.showBtn {
  opacity: 1;
  transform: translateY(0)
}


.sub-header {
  display: none !important;

  @media (min-width: 992px) {
    display: block !important;
  }

  .container {
    height: 100%;
  }

  .container {
    height: 100%;
    position: relative;

    @media (min-width: 1260px) {
      max-width: 1240px;
    }

    @media (min-width: 1400px) {
      max-width: 1300px;
    }

    @media (max-width: 1024px) {
      max-width: 100%;
    }

    @media (max-width: 992px) {
      padding: 0;
    }
  }

  ul {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: 100%;
    overflow: hidden;

    @media (max-width: 992px) {
      padding: 0;
    }

    li {
      position: relative;
      bottom: -4px;

      a.primary-btn {
        font-size: 14px;
        min-width: 110px;
        height: 40px;
        box-shadow: none !important;
        border-radius: 0;
        border-top-right-radius: 18px;
        border-top-left-radius: 18px;
        @include font-weight-style-3(400, normal);
        border: 1px solid #2B2E9F !important;
        background-color: #080B68 !important;

        &.active {
          color: #080B68;
          background-color: #FCB131 !important;
          border: 1px solid #FCB131 !important;
          @include font-weight-style-3(600, normal);
        }

        &.orange-btn {
          @extend .orange-btn;
        }
      }

      &+li {
        margin-left: 5px;
      }

      // &:last-child {
      //   a.primary-btn {
      //     background-color: $primary-color !important;

      //     &::after {
      //       content: '';
      //       display: block;
      //       background: url(../../../public/img/btn-arrow.png) no-repeat center center;
      //       width: 20px;
      //       height: 20px;
      //       position: relative;
      //       left: 5px;
      //       top: -1px;
      //     }

      //     &.active {
      //       background-color: #FCB131 !important;
      //     }
      //   }
      // }
    }
  }
}


/* ==========================================================================
   student-banner
	========================================================================== */

.home-banner-section {
  &.student-banner {
    padding: 200px 0 0 0;

    @media (max-width: 992px) {
      padding: 150px 0 0 0;
    }

    @media (max-width: 767px) {
      padding: 150px 0 0 0;
    }

    .register-section {
      .content {
        display: flex;

        .btn-space {
          align-items: center;
          left: 0;
        }
      }

      h4 {
        @include black-big-head();
        color: $white;
        @include font-weight-style-3(300, normal);

        @media (max-width: 1024px) {
          font-size: 28px;
        }

        @media (max-width: 992px) {
          text-align: center;
          font-size: 22px;
        }
      }

      .right-item {
        padding-left: 100px;

        @media (max-width: 992px) {
          margin-top: 70px;
          padding: 0 15px;
        }
      }
    }

    .student-blue-wrapper {
      background: url(../../../public/img/student-home/student-banner-bg.png) no-repeat top center;
      min-height: 700px;
      padding: 150px 40px 40px 40px;
      background-size: cover;
      position: relative;
      text-align: center;
      margin: 50px auto 0;
      text-align: center;
      color: $white;

      @media (max-width: 1024px) {
        padding: 150px 15px 20px 15px;
      }

      @media (max-width: 992px) {
        margin: 0 auto 0;
      }

      .book-wrapper {
        position: relative;
        margin: 100px auto 60px;
        text-align: center;
        display: block;
        width: 360px;
        height: 360px;

        @media (max-width: 1366px) {
          width: 300px;
          height: 340px;
        }

        @media (max-width: 1024px) {
          width: 280px;
          height: 320px;
          margin: 60px auto 60px;
        }

        .book-icon {
          width: 100%;
          height: 100%;
          z-index: 2;
          position: relative;
        }

        .book-circle {
          position: relative;
          top: -200px;
          width: 380px;
          height: auto;
          z-index: 1;

          @media (max-width: 1366px) {
            top: -160px;
          }
        }
      }

      h4 {
        color: $white;
        letter-spacing: -0.3px;
        font-size: 30px;
        line-height: 40px;
        max-width: 700px;
        margin: 0 auto;
        @include font-weight-style-3(200, normal);

        span {
          display: block;
          font-size: 18px;
          margin-top: 20px;
          font-style: italic;
        }

        @media (max-width: 767px) {
          font-size: 18px;
          line-height: 30px;
        }
      }

      h3 {
        color: $white;
        font-size: 30px;
        max-width: 700px;
        margin: 0 auto 30px;
        @include font-weight-style-3(600, normal);

        @media (max-width: 1024px) {
          font-size: 24px;
        }

        @media (max-width: 767px) {
          font-size: 16px;
        }
      }

      p {
        color: $white;
        font-size: 16px;
        @include font-weight-style-3(300, normal);

        @media (max-width: 1024px) {
          font-size: 14px;
        }

        span {
          color: #FFB642;
        }

        span.bold {
          color: $white;
          @include font-weight-style-3(700, normal);

          @media (max-width: 767px) {
            display: block;
          }
        }

        &+p {
          margin-top: 30px;
        }
      }

      .banner-icon {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          width: 80px;
          height: 80px;

          img {
            @include img();
          }
        }
      }
    }
  }
}

/* ==========================================================================
   resource-section
	========================================================================== */
.resource-section {
  background: #FFFCF6;
  padding: 80px 0;

  @media (max-width: 992px) {
    padding: 50px 0;
  }

  h3 {
    text-align: center;
    @include black-big-head();
    font-size: 35px;
    @include font-weight-style-3(600, normal);
    margin: 0 0 100px 0;

    @media (max-width: 992px) {
      font-size: 22px;
    }

    @media (max-width: 767px) {
      margin: 0 0 50px 0;
    }

    span {
      display: block;
      font-size: 24px;
      @include font-weight-style-3(400, normal);

      @media (max-width: 768px) {
        font-size: 15px;
      }
    }
  }

  h4 {
    text-align: center;
    @include black-big-head();
    font-size: 22px;
    @include font-weight-style-3(700, normal);
    margin: 20px 0;
    text-align: left;

    @media (max-width: 992px) {
      font-size: 16px;
    }

    @media (max-width: 767px) {
      text-align: center;
      margin: 10px 0;
    }
  }

  p {
    display: block;
    font-size: 13px;
    min-height: 180px;
    @include font-weight-style-3(400, normal);

    @media (max-width: 767px) {
      text-align: center;
      min-height: auto;
    }
  }

  .img-box {
    @media (max-width: 767px) {
      margin: 0 auto;
      display: block;
      width: 150px;
      height: 150px;
    }
  }

  .link-btn {
    padding: 0;
    color: $orange;
    font-size: 16px;
    margin-top: 30px;
    @include font-weight-style-3(700, normal);
    max-width: 110px;
    outline: 0;
    box-shadow: none;

    @media (max-width: 767px) {
      margin: 20px auto 0;
    }
  }

  .col-md-4 {
    padding: 0 40px;
    position: relative;

    @media (max-width: 767px) {
      &+.col-md-4 {
        margin-top: 60px;
      }
    }

    &::after {
      content: '';
      display: block;
      height: 75%;
      position: absolute;
      right: 3px;
      top: auto;
      bottom: 0;
      border-right: 1px solid #C4C4C4;

      @media (max-width: 767px) {
        display: none;
      }
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}




/* ==========================================================================
   feature-section
	========================================================================== */
.feature-section {
  background: $white;
  position: relative;
  overflow: hidden;

  h3 {
    text-align: center;
    @include black-big-head();
    font-size: 35px;
    @include font-weight-style-3(600, normal);
    margin: 0;

    @media (max-width: 992px) {
      font-size: 22px;
    }

    &.pd {
      padding: 100px 0;

      @media (max-width: 768px) {
        padding: 50px 0;
      }
    }

    span {
      display: block;
      font-size: 24px;
      @include font-weight-style-3(400, normal);

      @media (max-width: 768px) {
        font-size: 15px;
      }
    }
  }

  p {
    font-size: 16px;
    @include font-weight-style-3(300, normal);
  }

  .feature-data {
    position: relative;
    width: 100%;
    overflow: hidden;

    @media (max-width: 929px) {
      background: transparent !important;
    }
  }

  .sm-head {
    position: absolute;
    left: 0;
    top: 120px;
    font-size: 18px;
    color: $white;
    display: none !important;
    align-items: center;
    z-index: 10;
    display: none;
    @include font-weight-style-3(300, normal);

    span {
      display: inline-block;
      border-top: 1px solid rgba($white, 0.5);
      width: 150px;
      margin-right: 30px;
      position: relative;

      &::after {
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        padding: 1px;
        background-color: rgba($white, 0.8);
        position: absolute;
        right: 0;
        top: -2px;
      }
    }

    @media (max-width: 1366px) {
      top: 105px;
    }

    @media (max-width: 992px) {
      display: none;
    }
  }

  .nav-pills {
    position: absolute;
    left: 65px;
    flex-direction: column;
    top: 70%;
    display: none;
    transform: translateY(-70%);
    z-index: 10;

    @media (max-width: 1600px) {
      left: 40px;
    }

    @media (max-width: 1366px) {
      top: 80%;
      transform: translateY(-80%);
    }

    @media (max-width: 1024px) {
      left: 0;
    }

    @media (max-width: 992px) {
      display: none;
    }

    .nav-link {
      min-height: 100px;

      @media (max-width: 1366px) {
        min-height: 90px;
      }

      &.active {
        background-color: transparent;

        .text {
          font-size: 22px;
          top: -9px;
          @include font-weight-style-3(700, normal);

          @media (max-width: 1366px) {
            font-size: 18px;
          }
        }

        .list-content {
          &::before {
            background-color: $white;
          }
        }

        .sub-child {
          span {
            background-color: $white;
          }
        }
      }
    }

    .list-content {
      display: flex;
      position: relative;
      height: 80px;
      color: $white;

      @media (max-width: 1366px) {
        height: 75px;
      }

      @media (max-width: 1024px) {
        height: 70px;
      }

      &::before {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        padding: 0;
        color: $white;
        border: 1px solid $white;
        background-color: transparent;
      }

      .sub-child {
        position: relative;
        left: -8px;
        top: 16px;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media (max-width: 1366px) {
          top: 13px;
        }

        span {
          display: flex;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          padding: 1px;
          border: 1px solid $white;
          background-color: transparent;

          &+span {
            margin-top: 10px;
          }
        }
      }

      .text {
        margin-left: 30px;
        font-size: 18px;
        position: absolute;
        top: -5px;
        @include font-weight-style-3(400, normal);

        @media (max-width: 1366px) {
          font-size: 16px;
        }
      }
    }
  }

  .feature-wrapper {
    width: 100%;
    height: 100vh;
    top: 0;
    transition: background-color 0.5s ease;
    margin-bottom: 40px;

    @media (max-width: 992px) {
      position: relative !important;
      top: auto !important;
      height: auto !important;
      transform: unset !important;
    }

    &.purple-back {
      background-color: #9E2BD1;

      .feature-box {
        align-items: stretch !important;

        .para-box {
          @media (max-width: 767px) {
            margin-bottom: 10px;
          }
        }
      }
    }

    &.dark-blue-back {
      background-color: #1A1D43;
    }

    &.sky-blue-back {
      background-color: #246CFF;

      .feature-box {
        padding: 0 !important;
      }
    }

    &.pink-back {
      background-color: #FF00B9;

      @media (max-width: 1366px) {
        height: 600px;
      }
    }

    &.orange-back {
      background-color: #FF8E1F;
    }

    .container {
      min-height: 100%;
      display: flex;

      @media (max-width: 992px) {
        min-height: auto;
      }
    }

    &.mon-pd {
      padding: 50px 0 50px 0;

      @media (max-width: 767px) {
        padding: 50px 0 10px 0;
      }

      .container .feature-box {
        width: 100%;

        @media (max-width: 1600px) {
          padding: 50px 0 50px 0;
        }

        @media (max-width: 1024px) {
          padding: 50px 0 50px 0;
        }

        @media (max-width: 992px) {
          padding: 0 0 40px 0;
        }

        @media (max-width: 767px) {
          padding: 0;
        }
      }

      h3 {
        font-size: 36px !important;
        @include font-weight-style-3(300, normal);

        @media (max-width: 1024px) {
          font-size: 26px !important;
        }
      }

      h4 {
        font-size: 24px !important;

        @media (max-width: 992px) {
          font-size: 20px !important;
        }
      }

      .para-box {
        position: relative;
      }

    }

    .container {
      .feature-box {
        padding: 100px 0 0 0;
        display: flex;
        justify-content: center;
        overflow: hidden;
        align-items: center;
        min-height: 100%;

        @media (max-width: 992px) {
          padding: 20px 15px 20px 15px;
          margin-top: 0;
        }

        .center {
          display: flex;
          justify-content: center;
        }

        .feature-img {
          position: relative;
          width: 450px;

          @media (max-width: 1366px) {
            width: 320px;
          }

          @media (max-width: 1024px) {
            width: 270px;
            position: relative;
            left: 50px;
          }

          @media (max-width: 992px) {
            top: 0;
            margin: 0 auto;
            height: auto;
            left: 0;
            width: 240px;
          }

          @media (max-width: 640px) {
            height: auto;
          }
        }

        &.pd100 {
          padding: 50px 0 50px 0;

          @media (max-width: 992px) {
            padding: 40px 0 20px 0;
          }
        }
      }

      .para-box {
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 40px;

        h3 {
          color: $white;
          text-align: left;
          font-size: 26px;
          margin-bottom: 10px;

          @media (max-width: 1366px) {
            font-size: 24px;
          }

          @media (max-width: 992px) {
            font-size: 20px;
          }

          &.center {
            text-align: center;
            justify-content: center;
          }
        }

        h4 {
          font-size: 26px;
          color: $white;
          text-align: left;
          margin: 20px 0 10px 0;
          @include font-weight-style-3(600, normal);

          @media (max-width: 1366px) {
            font-size: 24px;
          }

          @media (max-width: 992px) {
            font-size: 20px;
          }
        }

        p {
          @media (max-width: 992px) {
            font-size: 14px;
          }

          &.center {
            text-align: center;
          }
        }
      }

      .banner-icon {
        top: 10px;
        display: block;
        position: relative;
        left: -20px;

        span {
          width: 200px;
          height: auto;
          display: block;

          @media (max-width: 767px) {
            width: 150px;
          }

          a {
            cursor: pointer;
            outline: 0 !important;
            box-shadow: none;
            width: 100%;
            min-width: 200px;
            padding: 0;
            justify-content: flex-start;

            @media (max-width: 767px) {
              min-width: 150px;
            }
          }

          // &+span {
          //   margin-left: -20px;
          // }

          img {
            @include img();
            width: 200px;
          }
        }
      }

      .purple-col {
        display: flex;
        justify-content: center;

        .top-phone-img-frame {
          display: flex;
          flex-direction: column;
          position: relative;
          background: url(../../../public/img/student-home/top-phone-face.png) no-repeat top center;
          background-size: 110%;
          width: 100%;
          height: 800px;

          .img-1 {
            width: 400px;
            height: 225px;
            transform: rotate(2deg);
            top: 62px;
            left: -13px;
            position: relative;
          }

          .img-2 {
            width: 400px;
            height: 200px;
            transform: rotate(0deg);
            top: -95px;
            left: 184px;
            position: relative;
          }

          .img-3 {
            width: 400px;
            height: 200px;
            transform: rotate(0deg);
            top: -143px;
            left: 170px;
            position: relative;
          }

          .img-4 {
            width: 400px;
            height: 200px;
            transform: rotate(0deg);
            top: -417px;
            left: 511px;
            position: relative;
          }

          .img-5 {
            width: 450px;
            height: auto;
            transform: rotate(0deg);
            top: -448px;
            left: 358px;
            position: relative;
          }
        }

        .left-img-frame {
          display: flex;
          flex-direction: column;
          position: relative;
          width: 280px;
          height: 280px;

          @media (max-width: 1366px) {
            width: 260px;
            height: 300px;
          }

          @media (max-width: 767px) {
            height: 220px;
          }

          &:nth-child(2) {
            margin: 40px 0 0 50px;

            @media (max-width: 767px) {
              margin: 40px 0 0 20px;
            }
          }

          &:last-child {
            margin: 80px 0 0 50px;

            @media (max-width: 767px) {
              margin: 80px 0 0 20px;
            }
          }

          &::before {
            content: '';
            display: block;
            background: url(../../../public/img/student-home/phone-face.png) no-repeat top center;
            padding: 0;
            background-size: 100%;
            width: 100%;
            height: 100%;

            @media (max-width: 767px) {
              background: url(../../../public/img/student-home/phone-face.png) no-repeat center center;
              background-size: 100%;
              width: 100%;
              height: 100%;
            }
          }

          p {
            font-size: 24px;
            color: rgba($white, 0.8);
            top: 55px;
            left: 63px;
            position: absolute;
            letter-spacing: 0.5px;
            @include font-weight-style-3(200, normal);

            @media (max-width: 992px) {
              left: 40px;
            }

            @media (max-width: 767px) {
              font-size: 11px;
              left: 19px;
              top: 55px;
            }
          }

          img {
            width: 250px;
            text-align: right;
            position: absolute;
            right: -10px;
            top: 0;

            @media (max-width: 1366px) {
              width: 210px;
              right: -15px;
            }

            @media (max-width: 992px) {
              width: 160px;
              right: 0;
            }

            @media (max-width: 767px) {
              width: 130px;
              right: -8px;
            }

            &.img-1 {
              top: 100px;

              @media (max-width: 767px) {
                top: 76px;
              }
            }

            &.img-2 {
              top: 215px;

              @media (max-width: 1366px) {
                top: 190px;
              }

              @media (max-width: 767px) {
                top: 127px;
              }
            }

            &.img-3 {
              top: 105px;

              @media (max-width: 767px) {
                top: 75px;
              }
            }

            &.img-4 {
              top: 250px;

              @media (max-width: 1366px) {
                top: 220px;
              }

              @media (max-width: 767px) {
                top: 143px;
              }
            }

            &.img-5 {
              top: 205px;

              @media (max-width: 1366px) {
                top: 190px;
              }

              @media (max-width: 767px) {
                top: 120px;
              }
            }
          }
        }
      }

      .monitor-img-box {
        position: relative;
        top: -80px;

        @media (max-width: 1366px) {
          left: 50px;
          top: -90px;
        }

        @media (max-width: 992px) {
          height: 580px;
          left: 0;
          top: 0;
        }

        @media (max-width: 767px) {
          height: 380px;
        }

        .monitor {
          display: block;
          position: absolute;

          h5 {
            @include p();
            color: $white;
            text-align: right;
            font-size: 18px;
            @include font-weight-style-3(300, normal);

            @media (max-width: 767px) {
              font-size: 12px;
            }
          }

          img {
            max-width: 100%;

            @media (max-width: 1366px) {
              max-width: 85%;
            }

            @media (max-width: 1024px) {
              max-width: 95%;
            }
          }

          h5 {
            position: absolute;
            left: 40px;
            top: -9px;
          }

          &:nth-child(2) {
            position: absolute;
            top: -44px;
            left: 0;
            z-index: 1;

            @media (max-width: 992px) {
              left: 38px;
              top: 110px;
              position: relative;
              z-index: 1;
            }

            @media (max-width: 767px) {
              left: 15px;
              top: 113px;
            }

            img {
              @media (max-width: 767px) {
                width: 350px;
              }
            }
          }

          &.child-3 {
            position: absolute;
            left: -30px;
            top: -177px;
            // z-index: -1;
            width: 100%;
            height: 100%;

            @media (max-width: 1367px) {
              top: -167px;
            }

            @media (max-width: 1024px) {
              top: -178px;
              left: -104px;
            }

            @media (max-width: 992px) {
              z-index: auto;
              top: -37px;
              left: 0;
            }

            @media (max-width: 767px) {
              top: 20px;
            }



            img {
              width: 280px;

              @media (max-width: 767px) {
                width: 200px;
              }
            }
          }

          &.child-4 {
            position: absolute;
            left: -125px;
            top: 0;
            // z-index: -1;
            right: 0;
            width: 100%;
            height: 100%;

            img {
              width: 650px;
            }

            @media (max-width: 992px) {
              z-index: auto;
              left: -63px;
              top: 140px;
            }

            @media (max-width: 767px) {
              left: -25px;
            }
          }

          &.child-5 {
            position: absolute;
            right: 80px;
            top: -190px;
            // z-index: -2;

            @media (max-width: 1366px) {
              top: -160px;
            }

            @media (max-width: 992px) {
              z-index: auto;
              position: absolute;
              top: 0;
              left: auto;
              right: 0;
            }

            img {
              width: 300px;

              @media (max-width: 1024px) {
                width: 270px;
                height: auto;
              }

              @media (max-width: 767px) {
                width: 180px;
              }
            }

            h5 {
              position: absolute;
              left: -130px;
              bottom: auto;
              top: 50px;
              text-align: left;
              line-height: 25px;

              @media (max-width: 992px) {
                left: -127px;
              }

              @media (max-width: 767px) {
                left: -86px;
              }
            }
          }
        }
      }
    }

    &.orange {
      background: $orange;
    }

    &.blue {
      background: $blue;
    }

    &.sea-green {
      background: $sea-green;
    }

    &.dark-blue {
      background: $dark-blue;
    }

    &.purple {
      background: #9E2BD1;
    }

    &.gsap-panel-5 .feature-box {
      .para-box {
        position: relative;
        top: -40px;
      }
    }
  }
}

.mon-para {
  @media (max-width: 1366px) {
    position: relative;
  }
}


/* ==========================================================================
   reward-section
	========================================================================== */
.reward-section {
  background: url(../../../public/img/student-home/reward-back.png) no-repeat top center;
  padding: 40px 20px;
  background-size: cover;
  position: relative;
  text-align: center;
  margin: 0 auto;
  text-align: center;
  color: $white;

  .container {
    min-height: 750px;
    position: relative;

    @media (max-width: 1024px) {
      min-height: 650px;
    }

    @media (max-width: 767px) {
      min-height: 450px;
    }

    .top-head {
      display: flex;
      min-height: 750px;

      @media (max-width: 1024px) {
        min-height: 650px;
      }

      @media (max-width: 767px) {
        min-height: 450px;
      }
    }
  }

  h3 {
    text-align: center;
    @include black-big-head();
    font-size: 50px;
    color: $white;
    letter-spacing: -0.3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include font-weight-style-3(600, normal);

    @media (max-width: 1024px) {
      font-size: 35px;
    }

    @media (max-width: 992px) {
      font-size: 22px;
    }

    span {
      display: block;
      color: $white;
      font-size: 25px;
      margin-top: 30px;
      letter-spacing: -0.3px;
      @include font-weight-style-3(400, normal);

      @media (max-width: 1024px) {
        font-size: 18px;
      }

      @media (max-width: 992px) {
        font-size: 15px;
      }
    }
  }
}


/* ==========================================================================
   build-section
	========================================================================== */
.build-section {
  position: relative;
  min-height: 650px;
  z-index: 1;
  overflow: hidden;

  @media (max-width: 992px) {
    min-height: auto;
  }

  .build-wrapper {
    background: url(../../../public/img/student-home/build-black-back.png) no-repeat top center;
    background-size: 100%;
    position: absolute !important;
    bottom: 0;
    height: 420px;
    width: 100%;

    @media (max-width: 1366px) {
      height: 390px;
    }

    @media (max-width: 1024px) {
      background: url(../../../public/img/student-home/build-black-back.png) no-repeat bottom center;
      background-size: 110%;
      height: 440px;
    }

    @media (max-width: 992px) {
      left: 0;
      bottom: 0;
      height: 340px;
      background: url(../../../public/img/student-home/build-black-back-mobile.png) no-repeat top center;
      background-size: 100%;
      display: none;
    }

    &.build-center {
      right: 0;
      left: auto;
    }

    .img-1 {
      position: absolute;
      top: -77px;
      left: -42px;
      z-index: -1;
      height: auto;
      width: 250px;
      rotate: -47deg;

      @media (max-width: 1366px) {
        top: -50px;
        left: 7%;
        width: 175px;
      }

      @media (max-width: 1024px) {
        top: 21px;
      }

      @media (max-width: 992px) {
        top: -90px;
        left: 0;
        width: 180px;
      }

      &.img-2 {
        left: 22%;
        top: -170px;
        rotate: none;

        @media (max-width: 1366px) {
          top: -113px;
          left: 28%;
        }

        @media (max-width: 1024px) {
          top: -20px;
        }

        @media (max-width: 992px) {
          top: -120px;
          width: 180px;
        }
      }

      &.img-3 {
        left: 80%;
        top: -167px;
        width: 330px;
        rotate: 33deg;

        @media (max-width: 1366px) {
          left: 75%;
          top: -85px;
          width: 230px;
        }

        @media (max-width: 1024px) {
          top: -5%;
          left: 73%;
        }

        @media (max-width: 992px) {
          top: -163px;
          left: 55%;
          width: 220px;
        }
      }

      &.img-4 {
        left: 50%;
        top: -251px;
        rotate: none;

        @media (max-width: 1366px) {
          top: -176px;
          width: 180px;
        }

        @media (max-width: 1024px) {
          top: -18%;
        }

        @media (max-width: 992px) {
          top: -150px;
          width: 160px;
          left: 80%;
        }
      }

      &.size2 {
        width: 300px;
        height: auto;
        top: -230px;

        @media (max-width: 1366px) {
          width: 230px;
          height: auto;
          top: -160px;
        }
      }

      &.size3 {
        width: 260px;
        height: auto;
        top: -270px;

        @media (max-width: 1366px) {
          width: 200px;
          height: auto;
          top: -200px;
        }
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      position: relative;
    }
  }

  .floating-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    @media (max-width: 992px) {
      position: absolute;
    }

    .reward-0 {
      position: absolute;
      top: 20%;
      left: 0;
      width: 250px;
      height: 190px;

      @media (max-width: 992px) {
        top: 7%;
        width: 180px;
        height: 150px;
      }
    }

    .reward-1 {
      position: absolute;
      top: 60%;
      left: 0;
      width: 40px;

      @media (max-width: 992px) {
        display: none;
        ;
      }

    }

    .reward-2 {
      position: absolute;
      top: 30%;
      left: 10%;
      width: 40px;

      @media (max-width: 992px) {
        display: none;
        ;
      }

    }

    .reward-3 {
      position: absolute;
      top: 10%;
      left: 15%;
      width: 35px;

      @media (max-width: 992px) {
        top: 21%;
        left: 5%;
      }
    }

    .reward-4 {
      position: absolute;
      top: 15%;
      left: 30%;
      width: 40px;

      @media (max-width: 992px) {
        top: 6%;
        width: 30px;
      }
    }

    .reward-5 {
      position: absolute;
      top: 15%;
      left: 40%;
      width: 80px;
      opacity: 0.6;

      @media (max-width: 992px) {
        display: none;
      }
    }

    .reward-6 {
      position: absolute;
      top: 15%;
      left: 60%;
      width: 40px;

      @media (max-width: 992px) {
        top: 3%;
        width: 18px;
      }

    }

    .reward-7 {
      position: absolute;
      top: 10%;
      left: 75%;
      width: 40px;

      @media (max-width: 992px) {
        top: 6%;
        width: 30px;
      }
    }

    .reward-8 {
      position: absolute;
      top: 15%;
      left: 80%;
      width: 40px;

      @media (max-width: 992px) {
        top: 20%;
        width: 30px;
      }
    }

    .reward-9 {
      position: absolute;
      top: 28%;
      left: 86%;
      width: 60px;

      @media (max-width: 992px) {
        display: none;
        ;
      }
    }

    .reward-10 {
      position: absolute;
      top: 55%;
      left: 90%;
      width: 130px;

      @media (max-width: 992px) {
        display: none;
        ;
      }
    }
  }

  .data {
    max-width: 980px;
    text-align: left;
    margin: 0 auto;
    padding-bottom: 30px;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    @include font-weight-style-3(400, normal);

    @media (min-width: 1550px) {
      max-width: 100%;
    }

    @media (max-width: 1366px) {
      max-width: 900px;
    }

    @media (max-width: 1024px) {
      max-width: 700px;
    }

    @media (max-width: 992px) {
      max-width: 100%;
      position: relative;
      top: 0;
      text-align: center;
      justify-content: center;
      padding: 0;
    }

    @media (max-width: 767px) {
      max-width: 100%;
    }

    h3 {
      color: $white !important;
      @include black-big-head();
      font-size: 28px;
      margin: 0 auto 40px;
      max-width: 500px;
      @include font-weight-style-3(700, normal);

      @media (max-width: 1024px) {
        font-size: 20px;
        max-width: 350px;
        margin: 0 auto 30px;
      }

      @media (max-width: 992px) {
        font-size: 20px;
      }

      @media (max-width: 767px) {
        span {
          display: block;
        }
      }
    }

    h4 {
      letter-spacing: -0.3px;
      color: $white !important;
      @include black-big-head();
      font-size: 16px;
      margin: 10px 0 0 0;
      @include font-weight-style-3(700, normal);

      @media (max-width: 992px) {
        font-size: 18px;
      }
    }

    p {
      letter-spacing: -0.3px;
      color: $white !important;
      margin: 10px 0 0 0;
      font-size: 14px;
      @include font-weight-style-3(300, normal);

      @media (max-width: 992px) {
        font-size: 14px;
      }

      @media (max-width: 1024px) {
        font-size: 12px;
      }
    }

    .shimmer-wrapper {
      height: 50px;

      @media (max-width: 1366px) {
        height: 40px;
      }

      @media (max-width: 992px) {
        height: 55px;
      }
    }

    img {
      width: 40px;
      height: auto;

      @media (min-width: 1440px) {
        width: 50px;
        height: auto;
      }

      @media (max-width: 1024px) {
        width: 35px;
        height: auto;
      }

      @media (max-width: 992px) {
        width: 50px;
        height: auto;
      }
    }
  }

  .mobile-build-box {
    height: 100%;
    // min-height: 550px;

    @media (min-width: 993px) {
      display: none;
    }

    .slick-slide {
      left: 0;
      bottom: 0;
      background: url(../../../public/img/student-home/build-black-back-mobile.png) no-repeat top center;
      background-size: 100%;
      position: relative;
      width: 100%;
      min-height: 340px;
      margin-top: 250px;
      padding: 0 15px;

      .img-1 {
        width: 200px;
        height: auto;
        position: absolute;
        top: -160px;
        left: 60px;
        right: 0;
        text-align: center;
        margin: 0 auto;
        z-index: -1;

        &.img-3 {
          width: 240px;
          top: -210px;
          left: 30%;
        }

        &.img-4 {
          width: 190px;
          top: -210px;
        }
      }

      .slider-data {
        position: relative;
        top: 50px;
      }
    }

    .slick-prev:before,
    .slick-next:before {
      font-size: 50px;
    }

    .slick-prev {
      left: 30px;
      top: 35%;
      font-size: 35px;
      z-index: 9;

      &::before {
        font-size: 0;
        content: '';
        display: block;
        background: url(../../../public/img/slick-prev-btn.png) no-repeat top center;
        background-size: 100%;
        width: 45px;
        height: 45px;
        opacity: 1;
      }
    }

    .slick-next {
      right: 50px;
      top: 35%;
      font-size: 35px;
      z-index: 9;

      &::before {
        font-size: 0;
        content: '';
        display: block;
        background: url(../../../public/img/slick-next-btn.png) no-repeat top center;
        background-size: 100%;
        width: 45px;
        height: 45px;
        opacity: 1;

      }
    }
  }
}

.build-section.gsap-panel {
  background: transparent;
}


/* ==========================================================================
  student-slider-wrapper
	========================================================================== */
.student-slider-wrapper {
  background: $white;
  padding: 50px 20px 100px 20px;
  position: relative;
  overflow: hidden;

  @media (max-width: 992px) {
    padding: 40px 20px 50px 20px;
  }

  h3 {
    @include black-big-head();
    @include font-weight-style-3(700, normal);

    @media (max-width: 992px) {
      text-align: center;
    }

    @media (max-width: 767px) {
      font-size: 22px;
    }
  }

  p {
    @include p();
    @include font-weight-style-3(400, normal);

    @media (max-width: 992px) {
      text-align: center;
    }

    .bold {
      @include font-weight-style-3(700, normal);
    }

    margin-bottom: 20px;
  }

  .btn {
    max-width: 250px;

    @media (max-width: 992px) {
      margin: 0 auto;
    }
  }

  .row {
    align-items: center;

    .col-lg-6 {
      &:first-child {
        @media (max-width: 992px) {
          margin-bottom: 20px;
        }
      }
    }
  }

  .student-slider-container {
    width: 100%;

    .slider-item {
      margin-bottom: 0;

      img {
        width: 350px;
        height: auto;
        margin: 0 auto;
      }
    }

    .slick-dots li button:before {
      font-size: 10px;
      color: #4582FF;
    }

    .slick-dots li.slick-active button:before {
      opacity: .75;
      color: #2401FE;
    }

    .slick-dots {
      @media (max-width: 992px) {
        bottom: 10px;
      }
    }
  }

  &.educator-topic {
    background: $blue;
    overflow: hidden;

    h3,
    p {
      color: $white;

      @media (max-width: 992px) {
        text-align: center;
      }
    }

    .btn {
      min-width: 220px;
      color: #001E3B !important;
      background-color: $white !important;
      @include font-weight-style-3(600, normal !important);

      @media (max-width: 992px) {
        margin: 0 auto;
      }
    }

    .row {
      @media (max-width: 992px) {
        flex-direction: column-reverse;
      }

      img {
        @media (max-width: 992px) {
          max-width: 500px;
          margin: 0 auto 20px;
        }

        @media (max-width: 992px) {
          max-width: 300px;
        }
      }
    }
  }
}


/* ==========================================================================
  our-user-wrapper
	========================================================================== */
.our-user-wrapper {
  background: $white;
  padding: 50px 20px 80px 0;
  position: relative;
  overflow: hidden;

  @media (min-width: 1400px) {
    padding: 80px 20px 100px 0;
  }

  @media (max-width: 992px) {
    padding: 50px 20px 50px 0;
    background-color: #F0F9FF;
  }

  &::after {
    content: '';
    display: block;
    width: 60%;
    height: 100%;
    right: 0;
    top: 0;
    position: absolute;
    background-color: #F0F9FF;
    z-index: 1;

    @media (max-width: 992px) {
      display: none;
    }
  }

  .container-fluid {
    padding: 0;
  }

  h3 {
    @include black-big-head();
    @include font-weight-style-3(700, normal);

    @media (max-width: 1024px) {
      text-align: center;
    }
  }

  p {
    @include p();

    @media (max-width: 1024px) {
      text-align: center;
    }

    .bold {
      @include font-weight-style-3(700, normal);
    }

    margin-bottom: 20px;
  }


  .btn {
    width: 300px;
    text-decoration: none;

    @media (max-width: 992px) {
      display: none;
    }



    &.mb-btn {
      display: block;
      margin: 30px auto 0;
      text-decoration: none;

      @media (min-width: 993px) {
        display: none !important;
      }
    }
  }

  .student-slider-container {
    width: 100%;

    @media (max-width: 992px) {
      margin: 0;
    }

    .slick-slider {
      .slick-prev:before {
        color: $blue;
        font-size: 0;
        content: '';
        display: block;
        background: url(../../../public/img/student-home/slider-arrow-sm-left.png) no-repeat;
        background-size: 100%;
        width: 30px;
        height: 30px;
      }

      .slick-next:before {
        color: $blue;
        font-size: 0;
        content: '';
        display: block;
        background: url(../../../public/img/student-home/slider-arrow-sm-right.png) no-repeat;
        background-size: 100%;
        width: 30px;
        height: 30px;
        margin-left: 10px;
      }

      .slick-prev,
      .slick-next {
        top: auto;
      }

      .slick-dots li button:before {
        font-size: 10px;
        color: #4582FF;
      }

      .slick-dots li.slick-active button:before {
        opacity: .75;
        color: #2401FE;
      }

      .slick-prev {
        bottom: -20px;
        display: block;
        left: auto;
        right: 4px;
        z-index: 7;
        position: absolute;
      }

      .slick-list {
        margin-bottom: 20px;
      }

      .slick-dots {
        bottom: -5px;
      }

      .slider-item {
        height: 450px;
        padding: 15px;

        @media (max-width: 992px) {
          height: 400px;
        }

        @media (max-width: 640px) {
          height: auto;
        }

        .star {
          width: 160px;
          margin-bottom: 15px;
          position: relative;
          left: -5px;

          @media (max-width: 767px) {
            width: 110px;
          }
        }
      }

      .slider-box {
        width: 100%;
        height: 100%;
        padding: 50px;
        border-radius: 38px;
        background: transparent;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: 767px) {
          padding: 40px 20px 20px 20px;
          min-height: 350px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
        }

        &.orange {
          background: $orange;
        }

        &.blue {
          background: $blue;
        }

        h3 {
          @include black-big-head();
          @include font-weight-style-3(600, normal);
        }

        p {
          @include p();
          font-size: 16px;
          color: $white;
          text-align: left;

          .bold {
            @include font-weight-style-3(700, normal);
          }

          @media (max-width: 767px) {
            font-size: 15px;
          }
        }

        .btn {
          margin-top: 20px;
          width: 220px;
        }

        .user-profile-inline {
          margin-top: 20px;
        }

        .profile-pic {
          width: 50px;
          height: 50px;
        }

        .profile-content {
          color: $white;
          font-size: 16px;
          margin-left: 15px;
          @include font-weight-style-3(300, normal);
        }
      }
    }
  }

  .left-item {
    z-index: 2;

    @media (max-width: 992px) {
      order: 2;
    }

    p.quote {
      position: relative;

      &::before {
        content: '';
        display: block;
        background: url(../../../public/img/student-home/quote.png) no-repeat top center;
        width: 100px;
        height: 100px;
        position: absolute;
        top: -77px;
        left: -27px;
        background-size: 100%;

        @media (max-width: 767px) {
          width: 50px;
          height: 50px;
          top: -50px;
          left: 0;
        }
      }
    }
  }

  .right-item {
    z-index: 2;

    h3 {
      font-size: 38px !important;

      @media (max-width: 767px) {
        font-size: 24px !important;
      }
    }

    p {
      font-size: 20px;
      color: $black !important;
    }

    .right-content {
      padding: 20px;
    }
  }
}


/* ==========================================================================
  student-vision
	========================================================================== */
.student-vision {
  background: url(../../../public/img/student-home/vision-back.png) no-repeat center center #FFEDC8;
  background-size: cover;
  position: relative;
  height: 600px;
  z-index: 1;

  @media (min-width: 1650px) {
    background: url(../../../public/img/student-home/vision-back.png) no-repeat top center #FFEDC8;
    background-size: cover;
  }

  @media (max-width: 767px) {
    min-height: 100%;
    height: 450px;
    background: url(../../../public/img/student-home/vision-back.png) no-repeat center center #FFEDC8;
    background-size: cover;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    padding: 20px 20px;
    min-height: 100%;
    max-width: 900px;

    @media (min-width: 1650px) {
      max-width: 1000px;
    }

    h3 {
      @include black-big-head();
      @include font-weight-style-3(700, normal);
    }

    p {
      @include p();
      margin-bottom: 20px;
      font-size: 24px;
    }
  }
}


/* ==========================================================================
  student-resource
	========================================================================== */
.student-resource {
  background: #FFFCF6;
  padding: 50px 20px;
  position: relative;

  .container {
    padding: 0;

    .row {
      margin: 0;
    }

    .heading {
      margin-bottom: 50px;

      h3 {
        text-align: center;
        @include black-big-head();
        @include font-weight-style-3(700, normal);
      }

      p {
        text-align: center;
        @include p();
        margin-bottom: 20px;
      }
    }

    .img-box {
      width: 250px;
      height: 250px;
      text-align: center;
      margin: 0 auto 20px;

      @media (max-width: 1024px) {
        width: 200px;
        height: 200px;
      }

      img {
        @include img();
      }
    }

    h4 {
      text-align: center;
      @include black-big-head();
      font-size: 24px;
      @include font-weight-style-3(700, normal);

      @media (max-width: 992px) {
        font-size: 20px;
      }
    }

    p {
      text-align: center;
      @include p();
      margin-bottom: 20px;

      @media (max-width: 992px) {
        font-size: 15px;
      }
    }
  }
}

/* ==========================================================================
  gsap for mobile
	========================================================================== */
html,
body {
  scroll-behavior: smooth,
}

@media(max-width:991.98px) {
  .gsap-panel .container {
    transform: unset !important;
  }

  .gsap-panel .container,
  .gsap-panel .build-wrapper-box {
    opacity: 1 !important;
  }

  .gsap-panel-1,
  .gsap-panel-13,
  .gsap-panel-14 {
    background: $orange;
  }

  .gsap-panel-2 {
    background: $blue;
  }

  .gsap-panel-3 {
    background: $dark-blue;
  }

  .gsap-panel-4 {
    background: $sea-green;
  }

  .gsap-panel-5,
  .gsap-panel-6 {
    background: $purple;
  }

  .gsap-panel-7,
  .gsap-panel-8 {
    background: $dark-blue !important;
  }

  .gsap-panel-9,
  .gsap-panel-10,
  .gsap-panel-11,
  .gsap-panel-12 {
    background: $pink !important;
  }

  .feature-wrapper {
    margin-top: 0;
  }
}

@media(min-width:992px) {

  .g-fade-in,
  .g-fade-out {
    transition: opacity 300ms ease-in, transform 300ms ease-in;
  }

  .gs-fade-in,
  .gs-fade-out {
    // transition: opacity 300ms ease-in;
  }

  .g-fade-out {
    opacity: 0;
    transform: scale(1.15);
  }

  .g-fade-in {
    opacity: 1;
    transform: scale(1);
  }

  .gs-fade-out {
    opacity: 0;
  }

  .gs-fade-in {
    opacity: 1;
  }

}