@import "../sass/variables";
@import "../sass/mixing";
@import "../sass/button";
@import "../sass/banner-v3.scss";
@import "../sass/home.scss";
@import "../sass/student-home.scss";
@import "../sass/parent-home.scss";
@import "../sass/educator-home.scss";
@import "../sass/school-home.scss";
@import "../sass/signup.scss";
@import "../sass/reg.scss";
@import "../sass/dashboard.scss";
@import "../sass/modal.scss";

body {
  width: 100%;
  font-size: 14px;
  @include font-weight-style(400, normal);
  background-color: $body-base;
}


/* input autofill color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: inset 0 transparent;
  -webkit-box-shadow: inset 0 0 0px 9999px $white !important;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: $black !important;
}

// safri key icon remove
::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  position: absolute;
  right: 0;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.main-container {
  width: 100%;
  min-height: 100%;
  height: 100%;
}

.wid100 {
  width: 100%;
}

.mgt0 {
  margin-top: 0 !important;
}

.mgb0 {
  margin-bottom: 0 !important;
}

.mgt10 {
  margin-top: 10px;
}

.mgb10 {
  margin-bottom: 10px !important;
}

.mgt15 {
  margin-top: 15px;
}

.mgt20 {
  margin-top: 20px;
}

.mgt30 {
  margin-top: 30px;
}

.mgt60 {
  margin-top: 60px;
}

.mgb10 {
  margin-bottom: 10px;
}

.mgb20 {
  margin-bottom: 20px;
}

.mgb30 {
  margin-bottom: 30px;
}

.mgl20 {
  margin-left: 20px;
}

.mlr0 {
  margin-left: 0;
  margin-right: 0;
}

.pdt0 {
  padding-top: 0 !important;
}

.pdb0 {
  padding-bottom: 0 !important;
}

.pd0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

textarea {
  min-height: 126px;
}

h1,
h2,
h3,
h4,
h5,
p,
.para {
  word-wrap: break-word;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

select::-ms-expand {
  display: none;
}

input::-ms-clear {
  display: none;
}

.disable-tab {
  pointer-events: none;
}

ul {
  list-style-type: none;
}

sup {
  top: -0.1em !important;
}


/* ==========================================================================
   form
	========================================================================== */
.form-group {
  margin-bottom: 1.2rem;

  label {
    color: $black;
    font-size: 14px;
    @include font-weight-style(700, normal);
    margin-bottom: 10px;
  }

  .form-control,
  input {
    background: $white;
    border-radius: 8px;
    height: 52px;
    padding: 6px 16px 6px 16px !important;
    font-size: 14px;
    color: $black;
    position: relative;
    border: 1px solid #C2C2C2 !important;
    border-color: #C2C2C2 !important;
    -webkit-appearance: none;
    transition: none;
    @include font-weight-style(500, normal);
    z-index: 1;

    &:focus {
      box-shadow: none;
    }
  }

  .react-tel-input .form-control {
    width: 100%;
    background: $white;
    border-radius: 8px;
    height: 52px;
    color: $black;
    border: 1px solid #C2C2C2 !important;
    @include font-weight-style(500, normal);
  }

  .select-custom {
    input {
      height: auto !important;
      border: 0 !important;
      border-color: transparent !important;
      width: 400px !important;
      padding-left: 0px !important;
    }
  }

  @include placeholder {
    font-size: 14px;
    color: rgba($black, 0.40);
    @include font-weight-style(400, normal);
  }

  .error {
    color: $primary-color;
    font-size: 12px;
    bottom: 0;
    margin-left: 0;
    position: absolute;
    display: none;
    @include font-weight-style(600, normal);
  }
}

/* ==========================================================================
   navbar
	========================================================================== */
header {
  width: 100%;

  .navbar {
    background: #2B2E9F;
    min-height: 80px;
    top: 0;
    left: 0;
    width: 100%;

    @media (max-width: 992px) {
      min-height: 80px;
      box-shadow: 0 0 6px 1px rgba(0, 0, 0, .1);
      padding: .5rem;
    }

    .container {
      height: 100%;
      position: relative;

      @media (min-width: 1260px) {
        max-width: 1240px;
      }

      @media (min-width: 1400px) {
        max-width: 1300px;
      }

      @media (max-width: 1024px) {
        max-width: 100%;
      }

      @media (max-width: 992px) {
        padding: 0;
      }

      .nav-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 992px) {
          width: 100%;
        }

        .seconday-btn {
          font-size: 16px;
          line-height: 26px;
          margin: 0;
          padding: 14px 25px;
          color: $primary-color;
          text-decoration: none;
          border-radius: 0;
          box-shadow: none;
          min-width: 150px;
          @include font-weight-style(600, normal);

          @media (min-width: 993px) {
            display: none;
          }
        }

        .menu-icon {
          margin-left: auto;
          padding-right: 15px;

          .line {
            width: 20px;
            height: 2px;
            background-color: #fff;
            display: block;
            margin: 7px auto;
            -webkit-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            opacity: 1;

            &:nth-child(2) {
              margin-left: 0;
            }

            &:first-child {
              width: 10px;
              margin-left: 0px;
            }

            &:nth-child(3) {
              width: 15px;
              margin-left: 0px;
            }
          }

          @media (min-width: 992px) {
            display: none;
          }

          &.active {
            .line {
              &:nth-child(2) {
                opacity: 0;
                -webkit-transform: translateX(100%);
                -ms-transform: translateX(100%);
                transform: translateX(100%);
              }

              &:nth-child(1) {
                -webkit-transform: translateY(5px) rotate(45deg);
                -ms-transform: translateY(5px) rotate(45deg);
                transform: translateY(5px) rotate(45deg);
                width: 15px;
              }

              &:nth-child(3) {
                -webkit-transform: translateY(-13px) rotate(-45deg);
                -ms-transform: translateY(-13px) rotate(-45deg);
                transform: translateY(-13px) rotate(-45deg);
              }
            }
          }
        }

        .navbar-brand {
          cursor: pointer;
          position: absolute;
          left: 30px;

          @media (max-width: 992px) {
            left: 15px;
          }

          img {
            max-width: 140px;
            height: auto;

            @media (max-width: 1024px) {
              max-width: 135px !important;
              height: auto;
            }

            @media (max-width: 568px) {
              max-width: 105px !important;
              height: auto;
            }
          }
        }
      }

      .menu {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 992px) {
          display: block;
          align-items: flex-start;
          height: calc(100vh - 80px);
          background: $white;
          width: 100%;
          opacity: 1;
          position: fixed;
          content: "";
          top: 80px;
          left: 0;
          overflow: auto;
          box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
          -webkit-transform: translateX(-1030px);
          -ms-transform: translateX(-1030px);
          transform: translateY(-1030px);
          -webkit-transition: opacity 1s ease-in-out,
            -webkit-transform 0.9s ease-in-out;
          -o-transition: transform 0.9s ease-in-out, opacity 1s ease-in-out;
          transition: transform 0.9s ease-in-out, opacity 1s ease-in-out,
            -webkit-transform 0.9s ease-in-out;
          padding: 10px 20px 0;
          z-index: 1 !important;
        }

        &.active {
          -webkit-transform: translateY(0);
          -ms-transform: translateY(0);
          transform: translateY(0);
          opacity: 1;
        }

        ul.menu-list {
          display: flex;
          align-items: center;
          margin: 0;
          padding: 0;

          @media (max-width: 992px) {
            align-items: flex-start;
            flex-direction: column;
            padding: 0;
            width: 100%;
          }

          li {
            @media (max-width: 992px) {
              width: 100%;
              height: 100%;
              display: block !important;
            }

            a.menu__link {
              font-size: 16px;
              line-height: 26px;
              margin: 0;
              padding: 15px 20px;
              color: $black;
              text-decoration: none;
              border-radius: 0;
              box-shadow: none;
              @include font-weight-style(300, normal);

              @media (max-width: 992px) {
                padding: 20px 10px;
                display: inline-block;
              }
            }

            .blue-btn {
              color: $white !important;
              background-color: $blue !important;
              border-color: $blue !important;

              @media (max-width: 992px) {
                height: 40px;
                padding: 8px 5px !important;
                width: 150px;
                margin-top: 10px !important;
                box-shadow: none !important;

              }

              a {
                color: $white !important;
                outline: none;
                text-decoration: none;
                font-size: 16px;
                @include font-weight-style-3(300, normal);
              }
            }

            .seconday-btn {
              min-width: 139px;
              color: $primary-color !important;

              @media (max-width: 992px) {
                background: transparent;
                border: transparent;
                box-shadow: none;
                height: auto !important;
                line-height: normal !important;
                padding: 30px 20px;
                font-size: 16px;
                min-width: auto;
              }
            }
          }
        }

        .mobile-socail {
          margin-top: 40px;

          @media (min-width: 992px) {
            display: none;
          }

          ul {
            display: flex;
            align-items: center;
            margin: 20px 0 0 0;
            padding: 0;
            flex-direction: row;

            li {
              display: flex;

              img {
                opacity: 0.6;
              }

              &+li {
                margin-left: 50px;
              }
            }
          }
        }

        ul.reg-header {
          display: flex;
          align-items: center;

          li {

            a {
              @media (max-width: 992px) {
                padding: 30px 10px;
                display: inline-block;
              }

              color: $white;
              @include font-weight-style-3(400, normal);

              &.active {
                color: #FCB131;
              }

              &.not-active {
                color: $white;

                @media (max-width: 992px) {
                  color: $black;
                }
              }
            }

          }

          li+li {
            margin-left: 15px;

            @media (max-width: 992px) {
              margin: 0;
            }
          }
        }

        .logout-dropdown {
          .name {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.header-scrolled {
  .navbar {
    background: $white;
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, .1);
  }
}

.banner-v1 {
  background: url(../img/banner-1.png) no-repeat;
  width: 100%;
  background-size: cover;
  min-height: 792px;
  position: relative;

  .gentle-wave {
    background: url(../img/internet.png) no-repeat;
    width: 100%;
    background-size: cover;
    height: 120px;
    position: absolute;
    bottom: 0;
    z-index: 2;
  }

  .container {
    display: flex;
    min-height: 100vh;
  }

  .left-banner {
    display: flex;
    width: 40%;
    flex-direction: column;
    justify-content: center;

    .small {
      font-size: 16px;
      color: $white;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      // @include font-weight-style-1(400, normal);
    }

    h1 {
      font-size: 72px;
      color: $white;
      letter-spacing: -2px;
      margin-bottom: 26px;
      // @include font-weight-style-1(400, normal);
    }

    p {
      font-size: 24px;
      color: $white;
      @include font-weight-style(400, normal);
    }

    .seconday-btn {
      width: 182px;
      margin-top: 64px;
    }
  }

  .right-banner {
    width: 60%;
    background: url(../img/common-bg.png) no-repeat left bottom;
    background-size: 100%;
    height: 700px;
    position: relative;
    z-index: 2;
  }
}

.home-loader {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: $white;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;

  img {
    width: 400px;
    height: 400px;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    margin: auto auto;
    display: block;
  }
}

/* ==========================================================================
  contact page
	========================================================================== */
.contact-container {
  .home-form {
    padding: 170px 0 200px 0;

    @media (max-width: 992px) {
      padding: 120px 0 130px 0;
    }

    @media (max-width: 767px) {
      padding: 120px 0 220px 0;
    }
  }
}