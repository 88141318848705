.btn {
  transition: background-color .5s ease;
  box-shadow: none;

  &:focus {
    outline: 0;
  }

  &.disabled,
  &:disabled {
    opacity: .65;
    pointer-events: none;
  }

  .btn-disable {
    opacity: 0.5;
    pointer-events: none;
  }
}

.primary-btn {
  border-radius: 47px;
  height: 56px;
  min-width: 163px;
  font-size: 18px;
  border: 1px solid $primary-color;
  cursor: pointer;
  color: $white;
  background-color: $primary-color !important;
  @include font-weight-style(600, normal);
  @include padding(5px, 20px, 5px, 20px);
  line-height: normal;
  outline: 0;
  box-shadow: 0px 3px 0px rgba(192, 57, 43, 1) !important;

  &:hover,
  &:focus,
  &:active {
    color: $white;
    background-color: darken($primary-color, 10%);
  }
}

.seconday-btn {
  border-radius: 47px;
  height: 54px;
  min-width: 163px;
  font-size: 18px;
  border: 1px solid $primary-color;
  cursor: pointer;
  color: $primary-color;
  background-color: transparent;
  @include font-weight-style(600, normal);
  @include padding(5px, 10px, 5px, 10px);
  line-height: normal;
  outline: 0;

  &:hover,
  &:focus,
  &:active {
    color: $primary-color;
    background-color: transparent;
  }
}

.blue-btn {
  border-radius: 20px !important;
  height: 48px;
  min-width: 149px;
  font-size: 18px;
  border: 1px solid $light-blue !important;
  cursor: pointer;
  color: $white;
  background-color: $light-blue !important;
  @include font-weight-style(500, normal);
  @include padding(5px, 10px, 5px, 10px);
  line-height: normal;
  box-shadow: 0px 3px 0px rgba(0, 89, 145, 1) !important;
  outline: 0;

  &:hover,
  &:focus,
  &:active {
    color: $white;
    background-color: $light-blue;
    box-shadow: 0px 3px 0px rgba(0, 89, 145, 1) !important;
  }

  @media (max-width: 767px) {
    height: 60px;
  }
}

.btn-gray {
  border-radius: 20px !important;
  height: 48px;
  min-width: 149px;
  font-size: 18px;
  border: 1px solid rgba(209 213 219) !important;
  cursor: pointer;
  color: $black;
  background-color: rgb(229 231 235) !important;
  @include font-weight-style(500, normal);
  @include padding(5px, 10px, 5px, 10px);
  line-height: normal;
  box-shadow: 0px 3px 0px rgba(156 163 175) !important;
  outline: 0;


  @media (max-width: 767px) {
    height: 60px;
  }
}


a.blue-btn {
  line-height: 35px;

  @media (max-width: 767px) {
    line-height: 45px;
  }
}

.btn-secondary {
  border-radius: 20px !important;
  height: 48px;
  min-width: 150px;
  font-size: 18px !important;
  border: 1px solid $light-blue !important;
  cursor: pointer;
  color: $white;
  background-color: $light-blue !important;
  @include font-weight-style(600, normal);
  @include padding(5px, 10px, 5px, 10px);
  line-height: normal;
  box-shadow: 0px 3px 0px rgba(0, 89, 145, 1) !important;
  outline: 0;

  &:hover,
  &:focus,
  &:active {
    color: $white;
    background-color: $blue;
  }

  @media (max-width: 767px) {
    height: 60px;
  }
}

.btn-black {
  border-radius: 10px !important;
  height: 42px;
  min-width: 170px;
  font-size: 16px !important;
  border: 1px solid #000 !important;
  cursor: pointer;
  color: $white !important;
  background-color: #000 !important;
  @include font-weight-style(600, normal);
  @include padding(5px, 10px, 5px, 10px);
  line-height: normal;
  box-shadow: none !important;
  outline: 0;

  &:hover,
  &:focus,
  &:active {
    color: $white;
    background-color: #000;
  }
}

.orange-btn {
  color: $white !important;
  background-color: $primary-color !important;
}

//-------button footer
.button-panel {
  width: 100%;
  text-align: right;

  .btn+.btn {
    margin-left: 15px;
  }
}

.center-btn {
  margin: 0 auto;
  text-align: center;

  .btn+.btn {
    margin-left: 15px;
  }
}

.btn-space {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .btn+.btn {
    margin-left: 20px;
  }
}

.btn-inline {
  display: flex;
  align-items: center;

  .btn+.btn {
    margin-left: 20px;
  }
}


.full-btn {
  width: 100%;

  .btn {
    width: 100%;
  }
}