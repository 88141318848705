.primary-color {
  color: $primary-color !important;
  font-weight: 900;
}

.banner-section {
  width: 100%;
  padding: 150px 0 0 0;

  h3 {
    @include black-big-head();
    font-style: italic;
    letter-spacing: -0.3px;
    font-size: 50px;
    margin-bottom: 10px;
    color: $primary-color;

    @media (max-width: 1024px) {
      font-size: 38px;
    }

    @media (max-width: 767px) {
      font-size: 28px;
    }
  }

  p {
    @include p();
  }

  .sub-head {
    @include black-big-head();
    margin-bottom: 15px;

    @media (max-width: 1024px) {
      font-size: 24px;
    }

    @media (max-width: 767px) {
      font-size: 20px;
    }
  }

  .btn-space {
    justify-content: flex-start;
    position: relative;

    &::after {
      content: '';
      display: block;
      background: url(../../../public/img/banner-ribon.png) no-repeat center;
      width: 224px;
      height: 312px;
      background-size: 150px;
      position: absolute;
      top: 0;
      left: -55px;
      z-index: -1;

      @media (max-width: 1024px) {
        left: -40px;
      }

      @media (max-width: 767px) {
        width: 112px;
        height: 312px;
        background-size: 100px;
        left: -10px;
        top: -42px;
      }
    }

    .btn {
      padding: 0;
      @include font-weight-style(800, normal);

      img {
        width: 280px;
      }
    }
  }

  img {
    max-width: 100%;
    height: 100%;
  }

  .ribon-wrapper {
    overflow: hidden;
  }

  .banner-ribon {
    width: 230px;
    height: 397px;
    position: absolute;
    bottom: -255px;
    left: -10px;
    z-index: -1;
  }

  .img-box {
    margin: 0 auto;
    display: block;
    text-align: center;

    img {
      width: 700px;
      max-width: 100%;
      height: 100%;
    }
  }

  .row {
    align-items: center;
  }

  .pd-right {
    padding: 0 0 0 30px;

    @media (max-width: 1024px) {
      padding: 40px 15px 0 15px;
    }
  }

  .pd-left {
    @media (max-width: 1024px) {
      padding: 40px 15px 0 15px;
    }
  }

  .primary-btn {
    margin-top: 20px;
  }

  .order-change {
    @media (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }
}

// benfits-section
.benfits-section {
  width: 100%;
  padding: 96px 15px;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    display: block;
    position: absolute;
    height: 400px;
    width: 130%;
    left: -204.78px;
    transform: rotate(155deg);
    z-index: -1;
    top: 400px;
    background: $grey-bg;
  }

  h3 {
    @include black-big-head();
    text-align: center;
  }

  p {
    @include p();
    text-align: center;
  }

  .container {
    .card-list {
      border: 1px solid #D9D9D9;
      border-radius: 24px;
      padding: 36px 22px 36px 22px;
      margin-top: 70px;
      z-index: 1;
      position: relative;
      background: $white;

      .bullet-list {
        padding: 0;
        margin: 0;
        padding-inline-start: 0;

        li {
          display: flex;
          padding: 16px 0;

          @media (max-width: 767px) {
            flex-direction: column;
          }

          &+li {
            border-top: 1px solid rgba($black, 0.12);
          }

          .inner-content {
            width: 33.33%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 15px 30px;

            @media (max-width: 767px) {
              width: 100%;
              padding: 15px 15px;
            }
          }

          p {
            text-align: left;
            margin: 0;
            font-size: 16px;
            text-indent: -1.2em;
            color: $black;
            @include font-weight-style(500, normal);

            &::before {
              display: inline-block;
              content: '';
              border-radius: 50%;
              height: 10px;
              width: 10px;
              margin-right: 0.5rem;
              position: relative;
              top: -2px;
              background-image: linear-gradient(#95ADE5, #004DFF, #004DFF);
            }

            &.green {
              &::before {
                background-image: linear-gradient(#22DA98, #00FFA3);
              }
            }

            &.yellow {
              &::before {
                background-image: linear-gradient(#FFCB58, #FFAF00);
              }
            }
          }
        }
      }

      h4 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        span {
          @include black-big-head();
          font-size: 20px;
          line-height: 28px;
          margin-bottom: 16px;
        }

        img {
          width: 30px;
          height: 30px;
          margin-bottom: 20px;
        }
      }
    }
  }
}

// our feature
.about-section {
  padding: 80px 0 80px 0;

  .container {
    h3 {
      @include black-big-head();
      text-align: center;
      margin-bottom: 15px;
      @include font-weight-style-3(700, normal);

      @media (max-width: 1024px) {
        font-size: 26px;
      }

      span {
        @include font-weight-style-3(400, normal);
      }

      @media (max-width: 767px) {
        text-align: center !important;
      }
    }

    p {
      @include p();
      text-align: center;
      min-height: 150px;
      @include font-weight-style-3(400, normal);

      @media (max-width: 767px) {
        text-align: center !important;
      }
    }

    .row {
      margin-top: 50px;

      .col-md-4 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        &:last-child {
          &::after {
            content: '';
            display: block;
            background: url(../img/ribon-right.png) no-repeat center;
            width: 224px;
            height: 312px;
            background-size: 90px;
            position: absolute;
            bottom: -174px;
            right: 0;
            z-index: -1;

            @media (max-width: 767px) {
              width: 140px;
              height: 312px;
              background-size: 70px;
              right: 0;
              bottom: -138px;
            }
          }
        }
      }

      .img-box {
        width: 200px;
        height: 200px;
        display: block;
        overflow: hidden;
        margin: 0 auto 20px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .content {
        padding: 0 100px;

        @media (max-width: 1024px) {
          padding: 0;
        }
      }
    }
  }
}

// our Values
.topic-section {
  width: 100%;
  padding: 96px 15px;
  text-align: center;
  overflow: hidden;
  background: url(../img/new-home/subject-bg.png) no-repeat bottom center;
  background-size: contain;
  background-color: $body-base;
  position: relative;

  @media (max-width: 767px) {
    padding: 80px 0 50px 0;
  }

  .head {
    max-width: 600px;
    margin: 0 auto 40px;

    @media (max-width: 767px) {
      max-width: 100%;
    }
  }

  h4 {
    font-size: 24px;
    color: $primary-color;
    @include font-weight-style(600, normal);
    margin-bottom: 20px;
  }

  h3 {
    @include black-big-head();
    @include font-weight-style-3(700, normal);
  }

  .tabs-pills {
    padding-bottom: 20px !important;
    margin-bottom: 10px;
    position: relative;

    .button-wrapper {
      display: none;

      @media (min-width: 767px) {
        display: none;
      }

      .btn {
        padding: 0;
        margin: 0;
        width: 50px;
        height: 50px;
        overflow: hidden;
        cursor: pointer;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        background-color: $white;
        border: 0;
        top: -5px;

        img {
          width: 10px;
          height: 14px;
        }

        &#prevButton {
          position: absolute;
          left: -15px;
        }

        &#nextButton {
          position: absolute;
          right: -15px;
          transform: rotate(178deg);
        }
      }
    }

    &.subject-tab-main {
      margin: 0 auto;
      max-width: max-content;

      .wrapper {
        @media (max-width: 767px) {
          margin-bottom: 0;
        }
      }

      .nav-pills {
        @media (max-width: 767px) {
          padding: 0;
        }
      }
    }
  }

  .wrapper {
    @media (max-width: 767px) {
      position: relative;
      margin: 0 auto;
      padding: 0;
      height: 40px;
      margin-bottom: 40px;
      border-radius: 50px;
      background-color: $white;
    }
  }

  .nav-pills {
    background-color: $white;
    border-radius: 50px;
    justify-content: space-between;

    @media (max-width: 767px) {
      margin-top: 0px;
      flex-wrap: nowrap;
      transition: transform 0.3s;
      overflow: auto;
      padding: 8px 0;
    }

    li.nav-item {
      .nav-link {
        min-width: 180px;
        height: 60px;
        border-radius: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include font-weight-style(600, normal);

        @media (max-width: 1024px) {
          min-width: 150px;
        }

        @media (max-width: 992px) {
          min-width: 110px;
        }

        @media (max-width: 767px) {
          height: 40px;
          min-width: 120px;
        }

        &.active {
          position: relative;
          background-color: $primary-color;
          @include font-weight-style(800, normal);
        }
      }

      &+li.nav-item {
        @media (max-width: 767px) {
          margin-left: 20px;
        }
      }
    }
  }

  .tab-content {
    .tab-pane {
      ul.list-group {
        flex-direction: row;
        flex-wrap: wrap;

        // &::after{
        //   content: '';
        //   display: block;
        //   background: url(../img/topic-ribon.png) no-repeat center;
        //   width: 224px;
        //   height: 312px;
        //   background-size: 90px;
        //   position: absolute;
        //   bottom: -261px;
        //   left: -55px;
        //   z-index: -1;
        // }
        li.list-group-item {
          margin: 10px 10px;
          border: 0;
          border-radius: 20px;
          height: 70px;
          width: calc(25% - 20px);
          display: flex;
          align-items: center;
          justify-content: flex-start;
          text-align: left;

          @media (max-width: 992px) {
            width: calc(33.33% - 20px);
          }

          @media (max-width: 767px) {
            width: calc(50% - 20px);
          }

          @media (max-width: 640px) {
            width: calc(100% - 20px);
          }

          .img-box {
            width: 40px;
            height: auto;
            margin-right: 15px;

            &.wid-50 {
              width: 50px;
            }

            img {
              width: 100%;
              height: 100%;
            }
          }

          span {
            font-size: 16px;
            color: $black;
            @include font-weight-style(700, normal);
          }

          &:first-child {
            &::before {
              content: '';
              display: block;
              background: url(../img/topic-book.png) no-repeat;
              width: 60px;
              height: 60px;
              position: absolute;
              top: -23px;
              left: 0;
              z-index: -1;
              background-size: 100%;
            }
          }

          &:nth-child(4) {
            &::before {
              content: '';
              display: block;
              background: url(../img/topic-pencil.png) no-repeat;
              width: 71px;
              height: 60px;
              position: absolute;
              top: -23px;
              right: 20px;
              z-index: -1;
              background-size: 40px;
              display: none;
            }
          }

          &:nth-child(2) {
            &::before {
              content: '';
              display: block;
              background: url(../img/topic-pencil.png) no-repeat;
              width: 71px;
              height: 60px;
              position: absolute;
              top: -23px;
              right: 20px;
              z-index: -1;
              background-size: 40px;
              display: none;

              @media (max-width: 767px) {
                display: block;
              }
            }
          }
        }
      }

      #math-tab-1 {
        li {

          &:first-child,
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(7),
          &:nth-child(8),
          &:nth-child(11) {
            .img-box {
              width: 50px !important;
            }
          }
        }
      }

      #math-tab-2 {
        li {

          &:first-child,
          &:nth-child(2),
          &:nth-child(5),
          &:nth-child(7),
          &:nth-child(8) {
            .img-box {
              width: 50px !important;
            }
          }
        }
      }

      #math-tab-3 {
        li {

          &:first-child,
          &:nth-child(2),
          &:nth-child(4),
          &:nth-child(9),
          &:nth-child(10) {
            .img-box {
              width: 50px !important;
            }
          }
        }
      }

      #math-tab-5 {
        li {

          &:first-child,
          &:nth-child(2),
          &:nth-child(3) {
            .img-box {
              width: 50px !important;
            }
          }
        }
      }

      #math-tab-6 {
        li {

          &:nth-child(5),
          &:nth-child(7),
          &:nth-child(8),
          &:nth-child(12),
          &:nth-child(14) {
            .img-box {
              width: 50px !important;
            }
          }
        }
      }

      #english-tab-1 {
        li {

          &:nth-child(1),
          &:nth-child(6),
          &:nth-child(9),
          &:nth-child(10),
          &:nth-child(11),
          &:nth-child(12),
          &:nth-child(15),
          &:nth-child(16) {
            .img-box {
              width: 50px !important;
            }
          }
        }
      }

      #english-tab-2 {
        li {

          &:nth-child(1),
          &:nth-child(6),
          &:nth-child(9),
          &:nth-child(10),
          &:nth-child(11),
          &:nth-child(12),
          &:nth-child(14) {
            .img-box {
              width: 50px !important;
            }
          }
        }
      }

      #english-tab-3 {
        li {

          &:nth-child(1),
          &:nth-child(6),
          &:nth-child(9),
          &:nth-child(10),
          &:nth-child(11),
          &:nth-child(12),
          &:nth-child(16) {
            .img-box {
              width: 50px !important;
            }
          }
        }
      }

      #english-tab-4 {
        li {

          &:nth-child(1),
          &:nth-child(6),
          &:nth-child(9),
          &:nth-child(10),
          &:nth-child(11),
          &:nth-child(12),
          &:nth-child(16),
          &:nth-child(18) {
            .img-box {
              width: 50px !important;
            }
          }
        }
      }

      #english-tab-5 {
        li {

          &:nth-child(1),
          &:nth-child(6),
          &:nth-child(9),
          &:nth-child(10),
          &:nth-child(11),
          &:nth-child(12),
          &:nth-child(16),
          &:nth-child(18) {
            .img-box {
              width: 50px !important;
            }
          }
        }
      }

      #english-tab-6 {
        li {

          &:nth-child(1),
          &:nth-child(6),
          &:nth-child(9),
          &:nth-child(10),
          &:nth-child(11),
          &:nth-child(12),
          &:nth-child(16),
          &:nth-child(18) {
            .img-box {
              width: 50px !important;
            }
          }
        }
      }
    }
  }
}

// free trail
.free-trail {
  padding: 96px 0;
  background: url(../img/bg.png) no-repeat $white center center;
  width: 100%;
  min-height: 550px;
  background-size: 100%;
  position: relative;

  @media (max-width: 767px) {
    padding: 50px 0;
  }

  .container {
    position: relative;

    .col-md-7 {
      @media (max-width: 767px) {
        margin-bottom: 180px;
      }
    }
  }

  .qr-wrapper {
    background: url(../img/new-home/qr-bg-yellow.png) no-repeat bottom center;
    background-size: 100%;
    min-height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: block;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .qr-container {
    position: absolute;
    bottom: -15px;
    z-index: 1;
    left: 0;
    right: 0;

    @media (max-width: 768px) {
      bottom: -40px;
    }

    @media (max-width: 767px) {
      display: none;
    }

    .text {
      color: $white;
      text-align: center;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100%;
      font-size: 20px;
      position: relative;
      left: 50px;
      @include font-weight-style-2(400, normal);

      @media (max-width: 1024px) {
        font-size: 16px;
        top: 10px;
        left: 0;
      }

      @media (max-width: 992px) {
        top: 0;
      }

      img {
        width: 60px;
        height: 48px;
        position: relative;
        top: -20px;
        left: 6px;

        @media (max-width: 1024px) {
          width: 39px;
          height: auto;
          top: -10px;
        }

        @media (max-width: 768px) {
          width: 34px;
          height: 25px;
        }
      }
    }

    .qr-box {
      position: absolute;
      right: 40px;
      top: -40px;

      img {
        width: 140px;
        position: relative;
        top: -40px;

        @media (max-width: 768px) {
          width: 100px;
          top: -15px;
          right: -18px;
        }
      }
    }
  }

  .row {
    overflow: hidden;
    margin: 0 15px;
  }

  .order-change {
    @media (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }

  h3 {
    @include black-big-head();
    font-style: italic;
    letter-spacing: -0.3px;
    font-size: 50px;
    margin-bottom: 10px;

    img {
      max-width: 100%;
      height: 100%;

      @media (max-width: 767px) {
        max-width: 250px;
      }
    }
  }

  p {
    @include p();

    @media (max-width: 767px) {
      font-size: 18px !important;
    }
  }

  .sub-head {
    @include black-big-head();
    margin-bottom: 15px;
  }

  .btn-space {
    margin-top: 30px;
    justify-content: flex-start;
    position: relative;

    a {
      outline: 0;
    }

    .btn {
      padding: 0;
      outline: 0;
      box-shadow: none;

      img {
        width: 280px;
      }
    }
  }

  img {
    max-width: 100%;
    height: 100%;
  }

  .content {
    img {
      width: 150px;
    }
  }

  .banner-icon {
    display: flex;
    align-items: center;
    position: relative;
    @include p();

    div {
      width: 80px;
      height: 80px;

      img {
        @include img();
      }
    }
  }
}


// faq
.faq-section {
  padding: 96px 0;
  width: 100%;

  @media (max-width: 767px) {
    padding: 50px 15px;
  }

  .container {
    h3 {
      @include black-big-head();
      text-align: center;
      margin-bottom: 30px;
      font-weight: 800;
    }

    p {
      @include p();
      text-align: center
    }

    .row {
      display: flex;
      align-items: center;

      @media (max-width: 767px) {
        flex-direction: column;
      }
    }

    .img-box {
      position: absolute;
      left: 30px;
      width: 50%;

      @media (max-width: 767px) {
        position: relative;
        width: 100%;
        left: 0;
        margin-bottom: 40px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .accordion {
      width: 50%;
      display: flex;
      flex-direction: column;
      margin-left: auto;

      @media (max-width: 767px) {
        width: 100%;
      }

      .card {
        border: 1px solid #F3EFEA;
        border-radius: 10px;

        &+.card {
          margin-top: 10px;

          .card-header {
            border-top: 0 solid rgba(0, 0, 0, .125);
          }
        }

        .card-header {
          padding: .75rem .75rem;
          background-color: $grey-bg;

          .btn-link {
            position: relative;
            padding: .375rem 0;
            color: $black;
            font-size: 16px;
            @include font-weight-style(600, normal);
            text-decoration: none !important;

            &::after {
              position: absolute;
              right: 0;
              top: 10px;
              content: '';
              display: block;
              background: url(../img/arrow-down.png) no-repeat center center;
              width: 25px;
              height: 25px;
            }

            &:not(.collapsed)::after {
              position: absolute;
              right: 0;
              top: 10px;
              content: '';
              display: block;
              background: url(../img/arrow-down.png) no-repeat center center;
              width: 25px;
              height: 25px;
              transform: rotate(180deg);
            }
          }
        }

        .card-body {
          padding: 0 .75rem .75rem .75rem;
          background-color: $grey-bg;
          font-size: 14px;
          color: rgba($black, 0.56);
        }
      }
    }
  }
}


// footer
.footer {
  background-color: #1C223E;
  padding: 40px;

  .container-fluid {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .logo {
    width: 180px;
    height: auto;
    margin: 0 auto 10px;

    @media (max-width: 767px) {
      width: 140px;
    }
  }

  p {
    color: $white;
    font-size: 18px;
    margin-bottom: 10px;

    @media (max-width: 992px) {
      font-size: 14px;
    }

    @media (max-width: 767px) {
      padding: 0 32px;
    }
  }

  h3 {
    @include black-big-head();
    color: $white;
    font-size: 30px;
  }

  ul.social-list {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 18px 0;

    li {
      color: $white;
      font-size: 16px;

      @media (max-width: 992px) {
        font-size: 14px;
      }

      &+li {
        margin-left: 8px;
      }

      img {
        width: 40px;
        height: 40px;
      }
    }
  }

  ul.menu-list {
    display: flex;
    flex-direction: column;
    padding: 0;

    li {

      // & + li{
      //   margin-top: 10px;
      // }
      a {
        color: $white;
        font-size: 18px;
        @include font-weight-style(700, normal);
        text-decoration: none;

        @media (max-width: 640px) {
          font-size: 12px;
        }
      }
    }
  }

  .btn-space {
    width: 100%;
    margin-top: 0;
    justify-content: center;
    position: relative;

    .btn {
      padding: 0;
      box-shadow: none;

      img {
        width: 180px;
        height: 100%;
      }

      &+.btn {
        margin-left: -20px;

        @media (max-width: 767px) {
          margin-left: 10px;
        }
      }
    }
  }

  .col-md-4 {
    &:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (max-width: 767px) {
        align-items: flex-start;
      }
    }
  }
}




.privacy-container {
  margin-top: 8rem !important;

  @media (max-width: 992px) {
    margin-top: 5rem !important;
  }

  &.coming-soon-container {
    margin: 0 !important;
    height: 100vh;
    display: flex;
    align-items: center;

    h3 {
      text-align: center;
      font-size: 70px;
      line-height: 70px;
      @include font-weight-style-3(700, normal);

      @media (max-width: 767px) {
        font-size: 60px;
      }
    }
  }

  .container {
    padding: 20px 30px;
  }

  h1 {
    font-size: 30px;
    color: $black;
    line-height: normal;
    margin-bottom: 20px;
    letter-spacing: -0.3px;
    text-decoration: underline;
    text-transform: capitalize;
    @include font-weight-style(700, normal);

    @media (max-width: 992px) {
      font-size: 24px;
    }
  }

  h3 {
    font-size: 32px;
    color: $black;
    line-height: normal;
    margin: 20px 0 20px 0;
    letter-spacing: -0.3px;
    text-align: left;
    @include font-weight-style(700, normal);

    @media (max-width: 992px) {
      font-size: 24px;
    }
  }

  h4 {
    font-size: 18px;
    color: $black;
    line-height: normal;
    margin-bottom: 5px;
    letter-spacing: -0.3px;
    @include font-weight-style(800, normal);
  }

  p {
    font-size: 18px;
    margin: 0 0 20px 0;
    color: $black;
    @include font-weight-style(400, normal);

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }

  ul {
    list-style-type: disc !important;
    font-size: 18px;
    margin: 0 0 30px 0;
    padding: 0 20px;
    color: $black;
    @include font-weight-style(400, normal);

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }

  .contact-us {
    max-width: 600px;
    margin: 0 auto;
  }


}