@font-face {
	font-family: 'otomanopee_oneregular';
	src: url('../font/otomanopeeone-regular-webfont.woff2') format('woff2'),
		url('../font/otomanopeeone-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'otomanopee_oneregular';
	src: url('../font/otomanopeeone-regular-webfont.woff2') format('woff2'),
		url('../font/otomanopeeone-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;

}



@mixin font-shorthand($weight: $font-weight-base, $style: $font-style-base) {
	font-weight: $weight;
	font-style: $style;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* ==========================================================================
   fonts
========================================================================== */
@mixin font-weight-style($weight: $font-weight-base, $style: $font-style-base) {
	font-family: 'Nunito', sans-serif;
	@include font-shorthand($weight, $style);
}

@mixin font-weight-style-1($weight: $font-weight-base, $style: $font-style-base) {
	font-family: 'Inter', sans-serif, serif;
	@include font-shorthand($weight, $style);
}

@mixin font-weight-style-2($weight: $font-weight-base, $style: $font-style-base) {
	font-family: 'otomanopee_oneregular', sans-serif, serif;
	@include font-shorthand($weight, $style);
}

@mixin font-weight-style-3($weight: $font-weight-base, $style: $font-style-base) {
	font-family: "Poppins", sans-serif;
	@include font-shorthand($weight, $style);
}

@mixin box-shadow($top, $left, $blur, $color) {
	-webkit-box-shadow: $top $left $blur $color;
	-moz-box-shadow: $top $left $blur $color;
	box-shadow: $top $left $blur $color;
}

@mixin shadow() {
	box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.02);
}

@mixin center() {
	display: flex;
	align-items: center;
	justify-content: center;
}

@mixin round($radius: 8px) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

@mixin placeholder {
	::-webkit-input-placeholder {
		@content
	}

	;

	:-moz-placeholder {
		@content
	}

	;

	::-moz-placeholder {
		@content
	}

	;

	:-ms-input-placeholder {
		@content
	}

	;
}

@mixin padding($top, $right, $bottom, $left) {
	padding-top: $top;
	padding-right: $right;
	padding-bottom: $bottom;
	padding-left: $left;
}

@mixin margin($top, $right, $bottom, $left) {
	margin-top: $top;
	margin-right: $right;
	margin-bottom: $bottom;
	margin-left: $left;
}

@mixin wid100() {
	width: 100%;
	float: left;
}

@mixin truncate() {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin flex() {
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

@mixin img() {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

@mixin clearfix() {
	&::after {
		display: block;
		content: "";
		clear: both;
	}
}

@mixin black-big-head() {
	font-size: 32px;
	color: $black;
	line-height: normal;
	margin-bottom: 20px;
	letter-spacing: -0.3px;
	@include font-weight-style-2(400, normal);

	@media (max-width: 992px) {
		font-size: 24px;
	}
}

@mixin p() {
	font-size: 16px;
	margin: 0;
	color: $black;
	@include font-weight-style(400, normal);

	@media (max-width: 767px) {
		font-size: 16px;
	}

	.bold {
		@include font-weight-style-3(700, normal);
	}

	a.white {
		color: $white !important;
	}

	a.blue {
		color: #004EFD !important;
	}
}


@mixin profile-pic() {
	width: 40px;
	height: 40px;
	overflow: hidden;
	background-color: rgba($black, 0.2);
	border-radius: 50%;

	@media (min-width: 767px) {
		width: 54px;
		height: 54px;
	}

	img {
		width: 100%;
		height: 100%;
	}
}

@mixin default-scrollbar() {
	::-webkit-scrollbar {
		width: 6px;
	}

	::-webkit-scrollbar-track {
		border-radius: 10px;
		background-color: #ccc;
	}

	::-webkit-scrollbar-thumb {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
	}
}

/* ==========================================================================
   reuse classes
	========================================================================== */
.user-profile-inline {
	display: flex;

	.profile-pic {
		@include profile-pic();
		display: flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
	}

	.profile-content {
		display: flex;
		justify-content: center;
		flex-direction: column;
		line-height: normal;
		font-size: 14px;
		margin-left: 15px;
		color: $black;
		@include font-weight-style(600, normal);

		@media (min-width: 767px) {
			margin-left: 30px;
			font-size: 20px;
		}

		span.detail {
			color: $black;
			font-size: $f-12;
			display: block;
			@include font-weight-style(400, normal);

			@media (min-width: 767px) {
				font-size: $f-16;
			}
		}
	}
}


/* ==========================================================================
   select-custom
	========================================================================== */
.select-custom {

	.css-hrjbw2-control,
	.css-lkdfl3-control {
		background: $white;
		border-radius: 8px;
		height: 52px;
		padding: 6px 16px 6px 16px;
		font-size: 14px;
		color: $black;
		border: 1px solid #C2C2C2 !important;
		border-color: #C2C2C2 !important;
		box-shadow: none;
	}

	.css-1rhcplz-menu {
		z-index: 2;
	}
}

/* ==========================================================================
   select-wrapper
========================================================================== */
.select-wrapper {
	position: relative;

	.select-custom {
		height: 52px;

		.select__control {
			background: $white;
			border-radius: 8px;
			height: 100%;
			padding: 6px 16px 6px 16px;
			font-size: 14px;
			color: $black;
			border: 1px solid #C2C2C2 !important;
			border-color: #C2C2C2 !important;
			box-shadow: none;
		}

		.select__menu {
			margin-top: 0;
			top: 56px;
		}
	}
}


/* ==========================================================================
   tooltip
========================================================================== */
.tooltip-box {
	position: relative;
	top: 6px;
	display: inline-block;
}

.tooltip-box .tooltiptext {
	visibility: hidden;
	min-width: 300px;
	background-color: #000;
	color: $white;
	text-align: left;
	border-radius: 6px;
	padding: 20px;
	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: 50%;
	margin-left: -60px;
	opacity: 0;
	font-size: 14px;
	transition: opacity 0.3s;

	@media (max-width: 640px) {
		margin-left: -250px;
	}

	@include font-weight-style(500, normal);
}

.tooltip-box .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 20%;
	margin-left: -5px;
	border-width: 9px;
	border-style: solid;
	border-color: #000 transparent transparent transparent;
}

.tooltip-box:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}

/* ==========================================================================
  floating-img
	========================================================================== */
.floating-img {
	width: 100%;

	.reward-1 {
		position: absolute;
		top: 10px;
		width: 30px;
		left: 5%;

		@media (max-width: 1024px) {
			width: 15px;
			left: 0;
		}
	}

	.reward-2 {
		position: absolute;
		top: 40px;
		left: 15%;
		width: 50px;
		opacity: 0.5;

		@media (max-width: 1024px) {
			width: 30px;
		}

		@media (max-width: 767px) {
			display: none;
		}
	}

	.reward-3 {
		position: absolute;
		top: 30px;
		left: 35%;
		width: 40px;

		@media (max-width: 1024px) {
			width: 25px;
		}

		@media (max-width: 767px) {
			width: 30px;
			left: 20%;
		}
	}

	.reward-4 {
		position: absolute;
		top: 30px;
		right: 20%;
		width: 50px;

		@media (max-width: 1024px) {
			width: 25px;
		}
	}

	.reward-5 {
		position: absolute;
		top: 100px;
		left: 50%;
		width: 30px;

		@media (max-width: 1024px) {
			width: 15px;
		}

		@media (max-width: 767px) {
			top: 60px;
		}
	}

	.reward-6 {
		position: absolute;
		top: 100px;
		right: 0;
		width: 30px;

		@media (max-width: 1024px) {
			width: 15px;
		}
	}

	.reward-7 {
		position: absolute;
		top: 150px;
		left: 0;
		width: 50px;

		@media (max-width: 1024px) {
			width: 35px;
		}

		@media (max-width: 767px) {
			left: -30px;
			top: 100px;
		}
	}

	.reward-8 {
		position: absolute;
		top: 222px;
		left: 20%;
		width: 30px;

		@media (max-width: 1024px) {
			width: 15px;
		}

		//   @media (max-width: 767px) {
		// 	display: none;
		//   }
	}

	.reward-9 {
		position: absolute;
		top: 256px;
		right: 15%;
		width: 25px;
		opacity: 0.5;

		@media (max-width: 1024px) {
			width: 15px;
		}

		@media (max-width: 767px) {
			display: none;
		}
	}

	.reward-10 {
		position: absolute;
		top: 400px;
		left: -10%;
		width: 25px;
		opacity: 0.5;

		@media (max-width: 1024px) {
			width: 15px;
		}

		@media (max-width: 468px) {
			top: 300px;
		}
	}

	.reward-11 {
		position: absolute;
		bottom: 0;
		left: 0;
		opacity: 0.5;
		width: 60px;

		@media (max-width: 1024px) {
			width: 15px;
		}

		@media (max-width: 767px) {
			width: 30px;
			left: -20px;
			bottom: 40px;
		}
	}

	.reward-12 {
		position: absolute;
		bottom: 0;
		left: 15%;
		width: 25px;

		@media (max-width: 1024px) {
			width: 15px;
		}
	}

	.reward-13 {
		position: absolute;
		bottom: 40px;
		left: 45%;
		width: 30px;
		opacity: 0.5;

		@media (max-width: 767px) {
			display: none;
		}
	}

	.reward-14 {
		position: absolute;
		bottom: -10px;
		left: 60%;
		opacity: 0.5;
		width: 50px;

		@media (max-width: 1024px) {
			width: 35px;
		}

		@media (max-width: 767px) {
			display: none;
		}
	}

	.reward-15 {
		position: absolute;
		bottom: 100px;
		left: 70%;

		@media (max-width: 767px) {
			left: 45%;
			width: 15px;
		}
	}

	.reward-16 {
		position: absolute;
		bottom: 0px;
		right: 10%;
		width: 40px;

		@media (max-width: 1024px) {
			width: 25px;
		}

		@media (max-width: 767px) {
			right: 24%;
		}
	}

	.reward-17 {
		position: absolute;
		bottom: 0px;
		right: -50px;
		opacity: 0.5;
		width: 50px;

		@media (max-width: 1024px) {
			width: 35px;
			right: 0;
		}

		@media (max-width: 767px) {
			bottom: 80px;
		}
	}
}

/* ==========================================================================
  bullet list data
========================================================================== */
ol.order-list-data {
	margin: 20px 0;
	padding-left: 10px;

	li {
		font-size: 16px;
		list-style-type: auto;
		@include font-weight-style-3(400, normal);
		color: $black;

		span.bold {
			@include font-weight-style-3(600, normal);
		}

		a {
			color: $blue;
			text-decoration: underline;
			@include font-weight-style-3(600, normal);
		}

		@media (max-width: 767px) {
			font-size: 14px;
		}
	}
}

ul.bullet-list-data {
	padding-left: 15px;
	margin: 10px 0 40px 0;

	li {
		list-style-type: disc;
		font-size: 16px;
		color: $black;
		@include font-weight-style-3(400, normal);

		span.bold {
			@include font-weight-style-3(600, normal);
		}

		span.red {
			color: #E74C3C;
		}

		@media (max-width: 767px) {
			font-size: 14px;
		}
	}
}

.yellow-box {
	background: $yellow-bg;
	padding: 15px;
	border-radius: 15px;
	margin-bottom: 10px;

	.box-heading {
		@include font-weight-style(700, normal);
		font-size: 20px;
		margin-bottom: 10px;
	}
}