/* ==========================================================================
   navbar
========================================================================== */
.olympiad-header {
  .olympiad-header-list {
    ul.social-list {
      justify-content: center;
      display: flex;
      margin-right: 15px;

      @media (max-width: 992px) {
        position: relative;
        top: 110px;
      }

      li {
        position: relative;

        &+li {
          margin-left: 5px;
        }

        img {
          width: 32px;
          height: 32px;
        }

      }

    }

    a.primary-btn {
      height: 40px !important;
      line-height: 15px !important;
      color: #FFFAF0 !important;
      border-radius: 20px !important;
      padding: 10px 20px !important;
      min-width: 168px;

      @media (max-width: 992px) {
        margin-top: 15px !important;
      }
    }

  }

  a.white-btn {
    height: 40px !important;
    line-height: 15px !important;
    color: white !important;
    border-radius: 20px !important;
    padding: 10px 20px !important;
    min-width: 168px;
    background-color: #2B2E9F;
    border: 1px solid $white;
    margin-right: 15px !important;
    position: relative;

    @media (max-width: 992px) {
      min-width: auto;
      height: auto !important;
      padding: 0 !important;
      margin: 0 !important;
      position: relative;
      top: -20px;
    }

    div.desktop-btn {
      height: 40px !important;
      line-height: 15px !important;
      color: white !important;
      border-radius: 20px !important;
      padding: 12px 20px !important;
      min-width: 100%;
      background-color: #2B2E9F;
      border: 1px solid $white;
      position: absolute;
      bottom: 2px;
      justify-content: center;
      display: flex;

      @media (max-width: 992px) {
        display: none;
        left: 0 !important;
        border: 1px solid transparent;
        box-shadow: 0px 3px 0px rgba(0, 89, 145, 1) !important;
      }

      img {
        width: 18px;
        height: 18px;
        position: relative;
        top: -2px;
        right: 3px;
      }
    }

    div.mobile-btn {
      display: none;

      @media (max-width: 992px) {
        display: block;
        width: 170px;
      }
    }
  }

  .mobile-playstore {
    position: absolute;
    right: 0;
    top: -16px;

    @media (min-width: 993px) {
      display: none;
      ;
    }

  }
}

.sub-header {
  position: fixed;
  top: 80px;
  width: 100%;
  height: 62px;
  z-index: 1030;
  background-color: #0C0F91;
  transition: top 300ms;

  @media (max-width: 1366px) {
    height: 55px;
  }
}

/* ==========================================================================
   home-banner-section
========================================================================== */
.home-banner-section {
  background: url(../img/new-home/header-bg.png) no-repeat top center #1C214D;
  min-height: 700px;
  padding: 150px 0 0 0;
  background-size: 100%;
  position: relative;

  @media (min-width: 1400px) {
    min-height: 750px;
  }

  @media (max-width: 992px) {
    min-height: 530px;
    padding: 100px 0 0 0;
  }

  @media (max-width: 767px) {
    min-height: 600px;
  }

  .container {
    @media (min-width: 1260px) {
      max-width: 1200px;
    }

    @media (min-width: 1400px) {
      max-width: 1350px;
    }
  }

  .register-section {
    z-index: 2;
    position: relative;

    .hide-mb {
      @media (max-width: 767px) {
        display: none;
        ;
      }
    }
  }

  .img-box {
    img {
      max-width: 100%;
      height: 100%;

      &.people-group {
        // width: 510px;
        margin-left: auto;
      }
    }
  }

  .content {
    .banner-img {
      position: relative;

      .banner-cloud {
        width: 650px;
      }

      .banner-heading {
        width: 550px;
        position: absolute;
        top: 10px;
        height: auto;
        left: 30px;

        @media (max-width: 1024px) {
          top: -7px;
          width: 500px;
        }

        @media (max-width: 767px) {
          left: 10px;
          top: -4px;
          max-width: 330px;
        }
      }

      .banner-date {
        width: 95px;
        position: absolute;
        bottom: -79px;
        height: auto;
        left: auto;
        right: 22px;
      }
    }
  }

  .btn-space {
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    @media (max-width: 992px) {
      top: 0;
      left: 0;
      align-items: center;
    }

    @media (max-width: 767px) {
      align-items: center;
    }

    &::after {
      display: none;
    }

    .olympiad-date {
      display: flex;
      align-items: center;

      span {
        color: $white;
        margin-left: 20px;
        font-size: 20px;
        @include font-weight-style-2(400, normal);
        position: relative;

        @media (max-width: 992px) {
          font-size: 18px;
        }

        &:after {
          content: "";
          display: block;
          border-bottom: 1px solid $white;
          height: 2px;
          position: absolute;
          width: 100%;
        }
      }
    }

    .primary-btn {
      min-width: 220px;
      height: 50px;
      border-radius: 20px;
      text-transform: uppercase;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

      @media (max-width: 767px) {
        min-width: 265px;
      }
    }
  }

  .qr-wrapper {
    background: url(../img/new-home/qr-bg.png) no-repeat bottom center;
    background-size: 100%;
    min-height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: block;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .qr-container {
    position: absolute;
    bottom: -15px;
    z-index: 1;
    left: 0;
    right: 0;

    @media (max-width: 768px) {
      bottom: -40px;
    }

    @media (max-width: 767px) {
      display: none;
    }

    .text {
      color: $white;
      text-align: center;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100%;
      font-size: 20px;
      position: relative;
      left: 50px;
      @include font-weight-style-2(400, normal);

      @media (max-width: 1024px) {
        font-size: 16px;
        top: 10px;
        left: 0;
      }

      @media (max-width: 992px) {
        top: 0;
      }

      img {
        width: 60px;
        height: 48px;
        position: relative;
        top: -20px;
        left: 6px;

        @media (max-width: 768px) {
          width: 34px;
          height: 25px;
        }
      }
    }

    .qr-box {
      position: absolute;
      right: 40px;
      top: -40px;

      img {
        width: 140px;
        position: relative;
        top: -40px;

        @media (max-width: 768px) {
          width: 100px;
          top: -15px;
          right: -18px;
        }
      }
    }
  }
}


/* ==========================================================================
   qr-wrapper-mb
========================================================================== */
.qr-wrapper-mb {
  z-index: 9;
  left: 0;

  @media (min-width: 768px) {
    display: none;
  }

  padding: 15px 15px;
  position: absolute;
  bottom: 0;
  background: url(../img/new-home/qr-mobile-back.png) no-repeat top center;
  width: 100%;
  background-size: 100%;
  height: 200px;

  p {
    color: $white;
    font-size: 15px;
    text-align: left;
    @include font-weight-style-2(500, normal);

    a {
      color: $white;
      text-decoration: underline;
    }
  }

  .qr-img {
    display: flex;
    justify-content: space-between;
  }

  img.toy-img {
    width: 100px;
    height: auto;
    align-self: center;
  }

  img.scanner {
    width: 100px;
    height: auto;
    position: relative;
    top: -67px;
  }
}

/* ==========================================================================
   detail-section
========================================================================== */
.detail-section {
  background: url(../img/new-home/detail-bg.png) no-repeat top center;
  min-height: 100%;
  position: relative;
  // top: -20px;
  background-size: cover;
  padding: 100px 0 100px 0;

  @media (max-width: 1024px) {
    padding: 100px 20px 100px 20px;
  }

  @media (max-width: 767px) {
    padding: 74px 20px 100px 20px;
  }

  .container {
    background: #FFE9C8;
    min-height: 100%;
    padding: 50px 60px;
    position: relative;
    z-index: 1;

    @media (min-width: 1200px) {
      max-width: 1000px;
    }

    @media (max-width: 767px) {
      padding: 50px 40px;
    }

    .hand {
      position: absolute;
      width: 100px;
      top: -12px;
      right: 124px;

      @media (max-width: 767px) {
        top: -8px;
        right: 53px;
      }
    }

    &::after {
      content: '';
      display: block;
      background: url(../img/new-home/detail-bg-rt.png) no-repeat top right;
      background-size: 100%;
      height: 90px;
      width: 130px;
      position: absolute;
      top: -79px;
      right: 100px;
      z-index: -1;

      @media (max-width: 767px) {
        top: -75px;
        right: 30px;
      }
    }

    &::before {
      content: '';
      display: block;
      background: url(../img/new-home/detail-bg-lt.png) no-repeat bottom left;
      background-size: 100%;
      height: 130px;
      width: 29px;
      position: absolute;
      top: -120px;
      left: 100px;

      @media (max-width: 767px) {
        top: -119px;
        left: 30px;
      }
    }

    h3 {
      @include black-big-head();
      text-align: center;
      margin-bottom: 30px;
      font-size: 28px;
      @include font-weight-style-2(400, normal);

      @media (max-width: 767px) {
        padding: 0;
        font-size: 20px;
      }

      &::after {
        content: '';
        display: block;
        background: url(../img/new-home/underline.png) no-repeat center center;
        height: 27px;
        width: 100%;
        background-size: 50%;

        @media (max-width: 767px) {
          width: 200px;
          background-size: 100%;
          margin: 0 auto;
        }
      }
    }

    h6 {
      font-size: 20px;
      margin: 35px 0 10px 0;
      text-decoration: underline;
      @include font-weight-style(600, normal);
    }

    p {
      color: $black;
      font-size: 20px;
      margin-top: 35px;
      @include font-weight-style(400, normal);

      a {
        text-decoration: underline;
      }

    }

    ul {
      li {
        color: $black;
        font-size: 20px;
        list-style-type: disc;
        @include font-weight-style(600, normal);

        @media (max-width: 767px) {
          font-size: 18px;
        }

        .seconday-btn {
          color: $black;
          height: 38px;
          border-radius: 20px;
          border-color: $black;

          @media (max-width: 767px) {
            font-size: 16px;
          }
        }

        .bold {
          @include font-weight-style(800, normal);
        }

        .link {
          color: $black;
          text-decoration: underline;
        }
      }
    }
  }

  &.instructions-section {
    background-image: url(../img/new-home/detail-bg.png) no-repeat top center;
    min-height: 100%;
    background-size: cover;
    padding: 0 0 0 0;
    top: 0;
  }

  .instructions-bg {
    padding: 20px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    background: linear-gradient(to right, #6B50E4, #3915D9);

    .inner-container {
      display: flex;
      padding: 20px 30px;
      min-width: 500px;
      border-radius: 23px;
      justify-content: space-between;
      background: #FFFAF0;

      @media (max-width: 600px) {
        min-width: 340px;
        padding: 20px 15px;
      }

      h3 {
        text-transform: uppercase;
        color: #000;
        font-size: 18px;
        display: flex;
        align-items: center;
        letter-spacing: -01px;
        @include font-weight-style-2(400, normal);

        @media (max-width: 600px) {
          font-size: 14px;
        }

        img {
          margin-left: 15px;

          @media (max-width: 600px) {
            margin-left: 10px;
            width: 25px;
          }
        }
      }

      .detail {
        .item {
          span.number {
            font-size: 18px;
            color: #E8505B;
            @include font-weight-style-2(400, normal);

            @media (max-width: 600px) {
              font-size: 16px;
            }
          }

          span.text {
            font-size: 18px;
            color: #000;
            text-transform: uppercase;
            @include font-weight-style-2(400, normal);

            @media (max-width: 600px) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .instructions-data {
    margin-top: 80px;

    @media (max-width: 600px) {
      margin-top: 90px;
    }
  }
}


/* ==========================================================================
   olympiad-about
========================================================================== */
.olympiad-about {
  background: url(../../../public/img/new-home/about-bg.png) no-repeat #FFFAF0 top center;
  background-size: contain;
  position: relative;

  @media (max-width: 767px) {
    padding: 50px 20px 50px 20px;
  }

  .container {
    .row {
      align-items: center;

      .img-box {
        width: 100%;
        height: 100%;

        img {
          max-width: 100%;
          height: 100%;
        }
      }
    }

    p,
    h3 {
      text-align: left;
    }

    .data {
      margin: 0 auto;
      max-width: 500px;
    }
  }
}

/* ==========================================================================
   olympiad-free
========================================================================== */
.olympiad-free {
  background: url(../img/new-home/free-bg.png) no-repeat $white center center;
  background-size: cover;

  .btn-space .btn img {
    width: 200px;
  }
}


/* ==========================================================================
   home-form
========================================================================== */
.home-form {
  padding: 60px 0 200px 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  background: url(../img/new-home/contact-bg.png) no-repeat left center $white;

  @media (max-width: 767px) {
    padding: 50px 15px 200px 15px;
  }

  &::after {
    content: '';
    display: block;
    background: url(../img/topic-ribon.png) no-repeat left center;
    width: 267px;
    height: 100%;
    background-size: 174px;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    z-index: 1;
    display: none;

    @media (max-width: 767px) {
      background: url(../img/topic-ribon.png) no-repeat top left;
      background-size: 84px;
      display: none;
    }
  }

  .container {
    position: relative;
    z-index: 2;

    @media (min-width: 1600px) {
      min-width: 1300px;
    }

    h3 {
      @include black-big-head();
      text-align: left;
      margin-bottom: 30px;
      font-weight: 800;
      @include font-weight-style-3(700, normal);
    }

    p {
      @include p();
      text-align: center
    }

    .row {
      display: flex;
      align-items: center;

      @media (max-width: 767px) {
        flex-direction: column;
      }
    }

    .contact-detail {
      max-width: 100%;
      min-height: 120px;
      padding: 25px 30px;
      border-radius: 30px;
      background: #FFF2E2;
      margin-top: 40px;

      @media (max-width: 767px) {
        margin: 40px 0;
      }

      ul {
        li {
          display: flex;

          span {
            font-size: 20px;
            margin-left: 30px;
            @include font-weight-style(400, normal);

            @media (max-width: 768px) {
              font-size: 18px;
              margin-left: 10px;
            }
          }

          &+li {
            margin-top: 25px;
          }
        }
      }
    }

    .img-box {
      img {
        width: 100%;
        height: 100%;
      }
    }

    .form {
      .center-btn {
        text-align: left;

        .blue-btn {
          background: #009DFF;
          border-radius: 20px;
          min-width: 120px;
          box-shadow: 0px 3px 0px rgba(0, 89, 145, 1) !important;
        }
      }
    }
  }

  .qr-wrapper {
    background: url(../img/new-home/qr-bg-yellow.png) no-repeat bottom center;
    background-size: 100%;
    min-height: 100%;
    width: 100%;
    position: absolute;
    bottom: -4px;
    left: 0;
    z-index: 1;
    display: block;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .qr-container {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;

    @media (max-width: 768px) {
      bottom: -40px;
    }

    @media (max-width: 767px) {
      display: none;
    }

    .text {
      color: $white;
      text-align: center;
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100%;
      font-size: 20px;
      position: relative;
      left: 50px;
      @include font-weight-style-2(400, normal);

      @media (min-width: 1400px) {
        align-items: flex-start;

      }

      @media (max-width: 1024px) {
        font-size: 16px;
        top: 10px;
        left: 0;
      }

      @media (max-width: 992px) {
        top: -27px;
        font-size: 14px;
      }

      img {
        width: 60px;
        height: 48px;
        position: relative;
        top: -20px;
        left: 6px;

        @media (max-width: 1024px) {
          width: 39px;
          height: auto;
          top: -10px;
        }

        @media (max-width: 768px) {
          width: 34px;
          height: 25px;
        }
      }
    }

    .qr-box {
      position: absolute;
      right: 40px;
      bottom: 15px;

      img {
        width: 140px;
        position: relative;

        @media (max-width: 768px) {
          width: 100px;
          top: -40px;
          right: -18px;
        }
      }
    }
  }
}




/* ==========================================================================
   olympiad-footer
========================================================================== */
.olympiad-footer {
  background: url(../img/new-home/footer-bg.png) no-repeat #1C223E bottom center;
  background-size: 100%;
  width: 100%;
  height: 100%;
  position: relative;

  .footer-bg {
    display: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 767px) {
    background: none;
    background-color: #1C223E;
    padding: 60px 22px;

    &::before {
      content: '';
      display: block;
      background: url(../img/new-home/lion.png) no-repeat left bottom;
      background-size: 50%;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 140px;
      height: 110px;
      z-index: 1;
    }

    &::after {
      content: '';
      display: block;
      background: url(../img/new-home/cloud.png) no-repeat right center;
      background-size: 70%;
      position: absolute;
      right: 0;
      bottom: 20%;
      width: 78px;
      height: 49px;
    }
  }

  .container {
    text-align: center;

    @media (max-width: 767px) {
      padding: 0;
    }

    .social-list {
      justify-content: center;
    }

    .menu-list {
      flex-direction: row;
      justify-content: center;
      margin-bottom: 20px;

      li {
        position: relative;

        &+li {
          margin-left: 40px;

          @media (max-width: 767px) {
            margin-left: 20px;
          }

          &::before {
            display: block;
            content: '';
            height: 20px;
            position: absolute;
            top: 3px;
            left: -20px;
            border-left: 2px solid $white;

            @media (max-width: 640px) {
              left: -8px;
            }
          }
        }
      }
    }
  }
}


/* ==========================================================================
   common-banner
========================================================================== */
.common-banner {
  background: url(../img/common-bg.png) no-repeat top center;
  // min-height: 468px;
  padding: 100px 0 50px 0;
  background-size: cover;
  position: relative;

  .container {
    position: relative;

    &::after {
      content: '';
      display: block;
      background: url(../img/new-home/banner-date.png) no-repeat;
      width: 280px;
      height: 155px;
      background-size: 100%;
      position: absolute;
      right: 0;
      top: 0px;

      @media (max-width: 1024px) {
        background-size: 70%;
      }

      @media (max-width: 992px) {
        display: none;
      }
    }

    color: $white;

    h3 {
      @include black-big-head();
      color: $white;
      font-size: 25px;
      @include font-weight-style(600, normal);
    }

    ol {
      margin-bottom: 30px;
      padding-inline-start: 20px;
      list-style-type: decimal;

      li {
        color: $white;
        font-size: 18px;
      }
    }

    p {
      color: $white;
      font-size: 18px;
    }
  }
}

.common-form {
  padding: 50px 0 100px 0;
  margin-top: 10rem;
}



/* ==========================================================================
   home modal
========================================================================== */
.instructions-modal {
  background: url(../img/new-home/detail-bg.png) no-repeat #F39C12 top center;
  background-size: cover;
  padding: 80px 10px 10px 10px;
  top: 0 !important;
  left: 0;
  width: 100%;
  position: fixed !important;
  z-index: 9999 !important;

  @media (max-width: 767px) {
    padding: 75px 10px 10px 10px;
  }

  .container {
    background: #FFE9C8;
    padding: 50px 60px;
    position: relative;

    @media (min-width: 1200px) {
      max-width: 1000px;
    }
  }

  @include default-scrollbar();

  .modal-content-data {
    margin: 0 auto;
    padding: 70px 20px 20px 20px;

    @media (max-width: 767px) {
      padding: 40px 20px 20px 20px;
    }

    &::before {
      top: -120px;
    }

    .close-icon {
      cursor: pointer;
      position: fixed;
      top: 20px;
      right: 20px;

      @media (max-width: 767px) {
        width: 25px;
        height: 25px;
        font-size: 17px;
        top: 15px;
      }
    }

    h3 {
      @media (max-width: 767px) {
        margin-bottom: 0;
      }
    }

    .modal-body-data {
      overflow: auto;
      max-height: 500px;

      @media (max-width: 1366px) {
        max-height: 370px;
      }

      @media (max-width: 767px) {
        max-height: 390px;
      }
    }
  }
}