.btn{
	@apply flex justify-center items-center font-medium rounded-lg
}

.btn:disabled{
	@apply bg-[#bbb] shadow-none;
}

.btn:disabled:hover{
	@apply bg-[#bbb];
}

.btn-primary{
	@apply text-[15px] leading-[22px] py-2 px-4 text-white bg-[#558BE4] hover:bg-[#659BFF] transition duration-300;
	box-shadow: 0px 0px 20px rgba(127, 14, 224, 0.20);
}

.btn-secondary {
	@apply text-[15px] leading-[22px] py-2 px-4 bg-[#558BE4]/30 hover:bg-[#558BE4]/40 transition duration-300;
}

.btn-red {
	@apply text-[15px] leading-[22px] py-2 px-4 bg-[#E5383B]/80 text-white border-2 transition ease-in-out duration-300 hover:bg-[#E5383B];
	box-shadow: 0px 0px 20px rgba(228, 139, 85, 0.20);	
}
.btn-green {
	@apply text-[15px] leading-[22px] py-2 px-4 bg-[#01C851]/80 text-white border-2 transition ease-in-out duration-300 hover:bg-[#01C851];
	box-shadow: 0px 0px 20px rgba(85, 228, 85, 0.20);	
}


/* button outline */
.btn-outline-primary{
	@apply text-[12px] leading-[22px] py-[6px] px-6 bg-[#558BE4]/10 text-[#558BE4] border-2 border-[#558BE4]/50 transition ease-in-out duration-300;
}

.btn-small-outline-primary{
	@apply text-[12px] py-[1px] px-4 bg-[#558BE4]/10 text-[#558BE4] border-2 border-[#558BE4]/50 transition ease-in-out duration-300;
}

.btn-outline-red {
	@apply text-[12px] leading-[22px] py-[6px] px-4 bg-[#E5383B]/10 text-[#E5383B] border-2 border-[#E5383B]/50 transition ease-in-out duration-300 hover:bg-[#E5383B]/20;
}
.btn-outline-green {
	@apply text-[12px] leading-[22px] py-[6px] px-4 bg-[#57AD55]/10 text-[#57AD55] border-2 border-[#57AD55]/50 transition ease-in-out duration-300 hover:bg-[#57AD55]/20;
}

.btn-outline-yellow {
	@apply text-[12px] leading-[22px] py-[6px] px-6 bg-[#FFB800]/10 text-[#DF9800] border-2 border-[#FFB800]/50 transition ease-in-out duration-300 hover:bg-[#FFB800]/20;
}

.btn-outline-white{
	@apply text-[12px] py-[2px] px-4 bg-[#fff] text-[#558BE4] border-2 border-[#558BE4]/50 transition ease-in-out duration-300 hover:bg-[#fafafa];
}
